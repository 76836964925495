import React, { useState, useEffect } from 'react'
import './Login.css'
import Axios from 'axios'
import cloudLogo from '../Media/Cloud.svg'
import { useForm, Controller } from "react-hook-form";
import TextField from '@mui/material/TextField';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import GreenCheck from '../Media/green_check copy.svg'
import { useNavigate, Link, Navigate } from 'react-router-dom';
import { Margin } from '@mui/icons-material';

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().max(32).required(),

}).required();


function Login() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
        
        email: '',
        password: '',
        
      }
  });

  const [loginStatus, setLoginStatus] = useState(false);

  const login = (data) => {
    Axios.post('https://blume-server.herokuapp.com/login',{
        email: data.email,
        password: data.password,
    }).then((response) => {
        if (!response.data.auth) {
            setLoginStatus(false);
            console.log('Failed')
        } else {
            localStorage.setItem('token', response.data.token)
          
            setLoginStatus(true);
            // navigate('/profile');
            console.log(response.data);
            // console.log(data.email);
            // console.log(response.data.result[0].username);
            // setCurrentUser(data.username);
            localStorage.setItem('email', JSON.stringify(data.email));
            localStorage.setItem('username', JSON.stringify(response.data.result[0].username));
            navigate('/homepage');
            
        }
    });
};



  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const navigate = useNavigate();

  const onSubmit = (data) => {
    
    // console.log(data);
    login(data);
    
 
  
  };

  //  navigate('/homepage');

  return (
    <div className='main-login'>
      <form onSubmit={handleSubmit(onSubmit)}>
          <div className='logo-box-login' style={{  overflow: 'visable', width: '50vw', height: '20vh', marginBottom: '10vh'}}>
        {/* <img style={{ height: '100%', overflow: 'visable', }} className='cloud-logo' src={cloudLogo} alt="cloud-logo"/> */}
        <img style={{  overflow: 'visable', width: '50vw', marginBottom: 0}} className='cloud-logo' src={cloudLogo} alt="cloud-logo"/>
      </div>

      <div style={{ marginTop: '1.75em' }} className='text-field-7'>

<Controller
  rules={{ required: true }}
  name='email'
  control={control}
  render={({ field }) => 
  <TextField 
  sx={{ width: '90vw' }}
  InputLabelProps={{ style: { marginLeft: '2vw',fontSize: '.9em', fontFamily: 'graphik-regular', color:  '#cccccc'} }} 
  inputProps={{ autoComplete: 'off' }}
  id="standard-basic" 
  variant="standard"
  label="Email"
  type="email"
  // name='email'
  className='text-field'
   {...field} />}
  // control={control}
/>
  
</div>

            <div className='text-field-7'>

      <Controller
        rules={{ required: true }}
        control={control}
        name='password'
        render={({ field }) =>  <FormControl sx={{ width: '90vw' }} variant="standard">
          <InputLabel sx={{ marginLeft: '2vw',fontSize: '.9em', fontFamily: 'graphik-regular', color:  '#cccccc' }} htmlFor="standard-adornment-password">Password</InputLabel>
        <Input
          id="standard-adornment-password"
          type={values.showPassword ? 'text' : 'password'}
          value={values.password}
          onChange={handleChange('password')}
          endAdornment={
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        >
          {values.showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    }
    {...field} />
      </FormControl>}
  
 
        />

      </div>
      <div className='small-text-row-login'>
      <div className='small-text-left-row-login'>  <input
            className='filter-checkbox'
            type="checkbox"
            
            /><span className='small-text-login'>Remember Me</span> </div>
      <div className='small-text-login'>Forgot Password?</div>

  </div>
  <div className='button-container-login'>
    <button type='submit' className='button2'>Login</button>
  </div>
  
  </form>
</div>
  )
}

export default Login

// <img src={GreenCheck} className='green-check-inbox'/>