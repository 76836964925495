import { namespace } from 'd3'
import React, { useState } from 'react'
import Navbar from '../Navbar/Navbar'
import './Blume17.css'
import ChartSelect from '../Charts/ChartSelect'
import IndustryDonutChart from '../IndustryComponents/IndustryDonutChart'
import IndustryComponent from '../IndustryComponents/IndustryComponent.js'
import addIcon from '../Media/Plus1.svg'
import { BrowserRouter as Router, Link, Link as RouterLink } from 'react-router-dom'
import { fakeInvestors } from '../FakeData/FakeInvestorData'
import MyInvestorsIndustryDonutChart from '../MyInvestors/MyInvestorsCharts/MyInvestorsIndustryDonutChart'
import MyInvestorsIndustryComponent from '../MyInvestors/MyInvestorsCharts/MyInvestorsIndustryComponent'

function Blume17() {

  const verifiedInvestorList = [
      {name: 'dylan'},
      {
        name: 'joe'
      },
      {
        name: 'simon'
      }
  ]
  function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }
  // const randomThree = shuffle(fakeInvestors).filter((investor, index) => index < 3);
  const topThree = fakeInvestors.slice(0, 3);

  const [ selectedInvestor, setSelectedInvestor] = useState('')
  const currentInvestor = fakeInvestors.filter(i => i.name == selectedInvestor);
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  

  const [isOpen, setIsopen] = useState(false);

  const ToggleInvestorPage = () => {
      isOpen === true ? setIsopen(false) : setIsopen(true);
  }

  const investorStocksTypes = currentInvestor.map((investor) => investor.stocks.map(stock => stock.type));
  const uniqueTypes = [...new Set(investorStocksTypes[0])];

  return (
    <>
      
      <div className='main-17'>
      {/* <Navbar /> */}
      <div className='content-17'>
        <div className='large-text-17'>
        Welcome to <span style={{ color: '#2436e7' }}>Blume.</span> <br/>Pick a verified investor. <br/>On us!
        </div>
        <hr className='divider-17'/>



        {topThree.map((investor, index) => {
          return (
            <div key={index} 
            className={` ${selectedInvestor === investor.name ? 'investor-profile-17-active' : 'investor-profile-17'}`}
        
            onClick={() => {setSelectedInvestor(investor.name); ToggleInvestorPage(); window.scroll(0,0);
            }}>
           
              <img src={investor.photo} className='profile-photo-17'/>
          
            <div className='profile-text-17'>
              <div className='profile-name-17'>{capitalizeFirstLetter(investor.name)}</div>
              <div className='profile-stats-17'>ROI: 38% Risk B</div>
              <div className='profile-bio-17'>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.</div>
              <div className='profile-tags-17'>#crypto #tech #growth #biotech</div>
            </div>
          </div>
          );
        })}

<div className={`filter-menu ${isOpen == true ? 'active' : ''}`}>

{currentInvestor.map((investor, index) => (


  <div className='main-investorSelect'>
      
     
      <div className='background-investorSelect'>
      
      
      <div className='user-profile-investorSelect'>
      <div className='logo-box-17'>
        <img  onClick={() => {ToggleInvestorPage();}} style={{  cursor: 'pointer', overflow: 'visable' }} className='addIcon' src={addIcon} alt="addIcon"/>
      
      </div>
        <div className='row-investorSelect'>

          <img src={investor.photo}  className='user-photo-investorSelect'/>
          
          {/* <div className='logo-box-17'>
            <img  onClick={() => {ToggleInvestorPage();}} style={{  cursor: 'pointer', overflow: 'visable' }} className='addIcon' src={addIcon} alt="addIcon"/>
          </div> */}

        </div>
        
        <div className='user-name-investorSelect'>{investor.name}</div>
        <div className='user-bio-investorSelect'>
         {investor.bio}
        </div>
       
      </div>
      <div className='chart-key-investorSelect'><div className='chart-key-blue-ball'></div>Simon’s Investments</div>
      <div className='chart-key-sp500'><div className='chart-key-green-ball'></div>S&P 500</div>
      </div>

      <div className='chart-investorSelect'>
        
        
        <ChartSelect />
      </div>
      
      <div className='strategy-investorSelect'>
        <div className='strategy-big-text-investorSelect'>Strategy</div>
        <div className='strategy-text-investorSelect'>
          {investor.strategy}
        </div>
        <hr className='strategy-divider-investoSelectr'/>
      </div>
      <div className='risk-chart-investorSelect'>
        <div className='main-chart-investorSelect'>
        <MyInvestorsIndustryDonutChart currentInvestor={currentInvestor} uniqueTypes={uniqueTypes} />
        </div>
      </div>
      <MyInvestorsIndustryComponent currentInvestor={currentInvestor} />
  
    </div>
))}
</div>

     {/* <div className='selected-investor-17'>Selected: {capitalizeFirstLetter(selectedInvestor)} </div>   */}
        
        <Link className='text-link' to='/homepage' component={RouterLink}>
        <button style={{ marginTop: 46 }}className='button2'>Please Select Your Investor</button>
        </Link>


        <div style={{ marginTop: 20, marginBottom: 23 }} className='random-button-17'> Choose for me </div>
      </div>
      
      </div>
    </>
  )
}

export default Blume17
