import React, { useState, useEffect } from 'react'
import './Blume11.css';
import Axios from 'axios'
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

import YearDropdown from './YearDropdown';
import HowDropdown from './HowDropdown';
import { useNavigate } from 'react-router-dom';

const schema = yup.object().shape({
  // birthyear: yup.number().positive().integer().required(),
  // risk: yup.string().required(),
  save: yup.boolean().required(), 
  income: yup.boolean().required(), 
  outpace: yup.boolean().required(), 
  earn: yup.boolean().required(), 
  // crypto: yup.boolean().required(), 
  // bluechip: yup.boolean().required(), 
  // utilities: yup.boolean().required(), 
  // tech: yup.boolean().required(), 
  // biotech: yup.boolean().required(), 
  // realestate: yup.boolean().required(), 
  // howdidyouhear: yup.string().required(),
}).required();


const Switch1 = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#C5C5C5' : '#2436E7',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#C5C5C5' : '#2436E7',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));






export default function Blume11() {





  const { control, handleSubmit, formState: { errors }, setValue, register } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
        // birthyear: '',
        // risk: 'low',
        save: false,
        income: false,
        outpace: false,
        earn: false,
        // crypto: false,
        // bluechip: false,
        // utilities: false,
        // tech: false,
        // biotech: false,
        // realestate: false,
        // howdidyouhear: '',
        
      }
  });



  const registerUser = (data) => {
    Axios.post('http://localhost:3001/register',{
      email: data.email,
      password: data.password,
      
    }).then((response) => {
        console.log(response);
    });
    };

    
  const navigate = useNavigate();

  const onSubmit = (data) => {
    console.log(
      data.save,
      risk,
      year,
      how
      );
      registerUser(data)
      navigate('/17');

    
    };

    const [year, setYear] = useState('The Year You Were Born');
    const [how, setHow] = useState('How Did You Hear About Us')

    const [crypto, setCrypto] = useState(false);
    const [blueChip, setBlueChip] = useState(false);
    const [utilities, setUtilities] = useState(false);
    const [tech, setTech] = useState(false);
    const [biotech, setBiotech] = useState(false);
    const [realEstate, setRealEstate] = useState(false);

    // const [save, setSave] = useState(false);
    // const [income, setIncome] = useState(false);
    // const [outpace, setOutpace] = useState(false);
    // const [earn, setEarn] = useState(false);

    

    var riskSelection;

    const [risk, setRisk] = useState('low');

    const LowRisk = () => {
      return ( <div className='low-risk-select-11'>
      <div className='low-risk-pill-11'>Low</div>
      <div className='low-risk-medium-11' onClick={() => setRisk('medium')}>Medium</div>
      <div className='low-thin-line-11'></div>
      <div className='low-risk-high-11'onClick={() => setRisk('high')}>High</div>
    </div>);
    }

    const MidRisk = () => {
      return ( 
      <div className='low-risk-select-11'>
      <div className='mid-risk-low-11' onClick={() => setRisk('low')}>Low</div>
      <div className='mid-risk-pill-11'>Medium</div>
      <div className='mid-risk-high-11' onClick={() => setRisk('high')}>High</div>
    </div>);
    }

    const HighRisk = () => {
      return(
      <div className='low-risk-select-11'>
              <div className='high-risk-low-11' onClick={() => setRisk('low')}>Low</div>
              <div className='high-thin-line-11'></div>
              <div className='high-risk-medium-11' onClick={() => setRisk('medium')}>Medium</div>
              <div className='high-risk-pill-11'>High</div>
            </div>);
    }
    
    



   

  return (
    <>
    <div className='main-11'>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='content-11'>
         <div style={{ marginBottom: 24 }}className='large-text-11'>
        Just a few more questions to optimize your <span style={{ color: '#2436e7' }}>Blume</span> experience.
        </div> 
        <div className='form-content-11'>
        <YearDropdown  year={year} setYear={setYear}/>
        
        
        <div style={{marginBottom: 21 }}className='label-text-11'>Risk Tolerance</div>
        {risk == 'low' && (
         <LowRisk />
       )}
       {risk == 'medium' && (
         <MidRisk />
       )} 
       {risk == 'high' && (
         <HighRisk />
       )}
        
        
        <div className='label-text-11' style={{ marginBottom: '19px'}}>Investment Goals</div>
        <div className='goals-container-11'>

          <div className='goal-11'>
            <div className='goal-label-11'>Save for retirement</div>
            <div className='goal-switch-11'>
              
            <Controller
              name="save"
              control={control}
              defaultValue={false}
              sx={{ml: 0}}
              render={({ 
              field: { onChange, value },
              fieldState: { error, invalid }
              })  => (
                <Switch1
                  value={value}
                  onChange={(event, value)=> onChange(value)}
                />
              )}
            />

            </div>
          </div>

          <div className='goal-11'>
            <div className='goal-label-11'>Income for next large expenditure</div>
            <div className='goal-switch-11'>
             
            <Controller
              name="income"
              control={control}
              defaultValue={false}
              sx={{ml: 0}}
              render={({ 
              field: { onChange, value },
              fieldState: { error, invalid }
              })  => (
                <Switch1
                  value={value}
                  onChange={(event, value)=> onChange(value)}
                />
              )}
            />

            </div>
          </div>

          <div className='goal-11'>
            <div className='goal-label-11'>Outpace Inflation</div>
            <div className='goal-switch-11'>
              
            <Controller
              name="outpace"
              control={control}
              defaultValue={false}
              sx={{ml: 0}}
              render={({ 
              field: { onChange, value },
              fieldState: { error, invalid }
              })  => (
                <Switch1
                  value={value}
                  onChange={(event, value)=> onChange(value)}
                />
              )}
            />

            </div>
          </div>

          <div className='goal-11'>
            <div className='goal-label-11'>Earn higher returns on your money</div>
            <div className='goal-switch-11'>

            <Controller
              name="earn"
              control={control}
              defaultValue={false}
              sx={{ml: 0}}
              render={({ 
              field: { onChange, value },
              fieldState: { error, invalid }
              })  => (
                <Switch1
                  value={value}
                  onChange={(event, value)=> onChange(value)}
                />
              )}
            />

            </div>
          </div>

        </div>

        <div className='label-text-11' style={{ marginBottom: '21px'}}>Mainly Interested In</div>
        <div className='interest-container'>

          <div className='interest-row' style={{ marginBottom: '17px'}}>
            {crypto?
             <div className='selected-interest-11' onClick={e => {
              setCrypto(false)
            }} >Crypto</div>
            :
            <div className='default-interest-11'  onClick={e => {
              setCrypto(true)
            }} >Crypto</div>
            }

            {blueChip?
             <div className='selected-interest-11' onClick={e => {
              setBlueChip(false)
            }} >Blue Chip</div>
            :
            <div className='default-interest-11'  onClick={e => {
              setBlueChip(true)
            }} >Blue Chip</div>
            }

            {utilities?
             <div className='selected-interest-11' onClick={e => {
              setUtilities(false)
            }} >Utilities</div>
            :
            <div className='default-interest-11'  onClick={e => {
              setUtilities(true)
            }} >Utilities</div>
            }
            
          </div>
          <div className='interest-row'>
            {tech?
             <div className='selected-interest-11' onClick={e => {
              setTech(false)
            }} >Tech</div>
            :
            <div className='default-interest-11'  onClick={e => {
              setTech(true)
            }} >Tech</div>
            }

            {biotech?
             <div className='selected-interest-11' onClick={e => {
              setBiotech(false)
            }} >Biotech</div>
            :
            <div className='default-interest-11'  onClick={e => {
              setBiotech(true)
            }} >Biotech</div>
            }

            {realEstate?
             <div className='selected-interest-11' onClick={e => {
              setRealEstate(false)
            }} >Real Estate</div>
            :
            <div className='default-interest-11'  onClick={e => {
              setRealEstate(true)
            }} >Real Estate</div>
            }
            
          </div>


        </div>
        <div style={{ marginTop: 43, marginBottom: 35 }}>
        <HowDropdown how={how} setHow={setHow}/>
        </div>
        

        <div className='submit-button-container-11'>
          <button type='submit'
          id='submit' className='button2'>Finish Account Setup</button>
        </div>

        </div>

      </div>
      </form>
    </div>
      
    </>
  )
}
