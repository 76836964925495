import React, {useState} from 'react'
import './Dropdown.css';

function RiskDropdown({risk, setRisk}) {

const [isActive, setIsActive] = useState(false);
const risks = ['A', 'B', 'C', 'D']


  
  return (
    <div className='dropdown-leaderboard'>
      {isActive ? 
       <div className='dropdown-button-active-leaderboard' onClick={(e) =>
        setIsActive(!isActive)}>
        Select Risk <div className='dropdown-arrow-active-leaderboard'></div>
        </div>
      :
      <div className='dropdown-button-selected-leaderboard' onClick={(e) =>
        setIsActive(!isActive)}>
        {risk}<div className='dropdown-arrow-leaderboard'></div>
        </div>
       }
      
      {isActive && (
        <div className="dropdown-content-leaderboard">
          {risks.map((risk, index )=> (
            <div key={risks.risk}
            onClick={e => {
              setRisk(risk)
              setIsActive(false)
            }} 
            className="dropdown-item-leaderboard">{risk}</div>
          ))}
        
        
      </div>
      )}
      
      
    </div>
  )
}

export default RiskDropdown
