import React, {useState} from 'react'
import './Blume15.css';

function YearDropdown15({year, setYear}) {

const [isActive, setIsActive] = useState(false);
const years = [1991, 1992, 1993, 1994,1991, 1992, 1993, 1994,1991, 1992, 1993, 1994,1991, 1992, 1993, 1994,]
  return (
    <div className='dropdown'>
      {isActive ? 
       <div className='dropdown-button-active' onClick={(e) =>
        setIsActive(!isActive)}>
        Select a Year <div className='dropdown-arrow-active'></div>
        </div>
      :
      <div className='dropdown-button-selected' onClick={(e) =>
        setIsActive(!isActive)}>
        {year}<div className='dropdown-arrow'></div>
        </div>
       }
      
      {isActive && (
        <div className="dropdown-content">
          {years.map(year => (
            <div 
            onClick={e => {
              setYear(year)
              setIsActive(false)
            }} 
            className="dropdown-item">{year}</div>
          ))}
        
        
      </div>
      )}
      
      
    </div>
  )
}

export default YearDropdown15
