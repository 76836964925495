import React from 'react'
import notificationIcon from '../Media/WhiteBell.svg'
import './Navbar.css'
import WhiteMenuButton from '../Menu/WhiteMenuButton'
import MenuButton from '../Menu/MenuButton'
import { BrowserRouter as Router, Link, Link as RouterLink } from 'react-router-dom'
import { Margin } from '@mui/icons-material'

function HomepageNavbar() {
  return (
    <>
   

    

   <div className='navbar-container'>
        <div className='menu-icon'>
          
          <WhiteMenuButton />
        </div>

        <div className='notification-icon'>
        <Link className='text-link' to='/notifications' component={RouterLink}>
          <img style={{ height: 60}}src={notificationIcon}/>
          </Link>
        </div>
        
      </div>
    </>
  )
}

export default HomepageNavbar
