import React, {useState} from 'react'
import './Blume11.css';

function YearDropdown({year, setYear}) {

const [isActive, setIsActive] = useState(false);
// const years = [1991, 1992, 1993, 1994,1991, 1992, 1993, 1994,1991, 1992, 1993, 1994,1991, 1992, 1993, 1994,]

const years = Array.from(Array(new Date().getFullYear() - 1900), (_, i) => (i + 1901).toString()).reverse();

  return (
    <div className='dropdown'>
      {isActive ? 
       <div className='dropdown-button-active' onClick={(e) =>
        setIsActive(!isActive)}>
        Select a Year <div className='dropdown-arrow-active'></div>
        </div>
      :
      <div className='dropdown-button-selected' onClick={(e) =>
        setIsActive(!isActive)}>
        {year}<div className='dropdown-arrow'></div>
        </div>
       }
      
      {isActive && (
        <div className="dropdown-content">
          {years.map((year, index) => (
            <div key={index}
            onClick={e => {
              setYear(year)
              setIsActive(false)
            }} 
            className="dropdown-item">{year}</div>
          ))}
        
        
      </div>
      )}
      
      
    </div>
  )
}

export default YearDropdown
