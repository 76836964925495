import React, {useState} from 'react'
import './BrowseInvestors.css';

function BrowseYearDropdown({year, setYear}) {

const [isActive, setIsActive] = useState(false);
const risks = ['A', 'B', 'C', 'D']
const years = ['All', '5 Year', '1 Year', 'Month', 'Week', 'Day']

  return (
    <div className='dropdown-browse'>
      {isActive ? 
       <div className='dropdown-button-active-browse' onClick={(e) =>
        setIsActive(!isActive)}>
        Select Time <div className='dropdown-arrow-active-browse'></div>
        </div>
      :
      <div className='dropdown-button-selected-browse' onClick={(e) =>
        setIsActive(!isActive)}>
        {year}<div className='dropdown-arrow-browse'></div>
        </div>
       }
      
      {isActive && (
        <div className="dropdown-content-browse">
          {years.map(year => (
            <div key={years.year}
            onClick={e => {
              setYear(year)
              setIsActive(false)
            }} 
            className="dropdown-item-browse">{year}</div>
          ))}
        
        
      </div>
      )}
      
      
    </div>
  )
}

export default BrowseYearDropdown
