import React, { useState, useRef, useEffect } from 'react'
import * as d3 from 'd3'
import { select } from 'd3';
import './YearLineChart.css'

function YearLineChart({chart, setChart}) {
    // const [data] = useState([10,20,30,40,30,50,10,20,30,40,30,50]);
    const [data] =  useState([4446.59,
    4397.45,
    4412.53,
    4488.28,
    4500.21,
    4481.15,
    4525.12,
    4582.64,
    4545.86,
    4530.41,
    4602.45,
    4631.6,
    4575.52,
    4543.06,
    4520.16,
    4456.24,
    4511.61,
    4461.18,
    4463.12,
    4411.67,
    4357.86,
    4262.45,
    4173.11])

    const [data2] =  useState([4446.59,
      4497.45,
      4512.53,
      4588.28,
      4600.21,
      4581.15,
      4625.12,
      4682.64,
      4645.86,
      4630.41,
      4702.45,
      4731.6,
      4675.52,
      4643.06,
      4620.16,
      4556.24,
      4611.61,
      4561.18,
      4563.12,
      4511.67,
      4457.86,
      4362.45,
      4373.11])
    const svgRef = useRef();

    useEffect(() => {
        const w = document.body.clientWidth
        const h = 244;
        const svg = d3.select(svgRef.current)
          .attr('width', w)
          .attr('height', h)
          .style('background', '#FFFFFF')
          .style('margin-top', '0');

        const xScale = d3.scaleLinear()
          .domain([0, data.length - 1])
          .range([0, w]);
        const yScale = d3.scaleLinear()
          .domain([4000, 5000])
          .range([h, 0 ]);
        const generateScaledLine = d3.line()
          .x((d, i) => xScale(i))
          .y(yScale);
         


        svg.selectAll('.line')
          .data([data])
          .join('path')
            .attr('d', d => generateScaledLine(d))
            .attr('fill', 'none')
            .attr("stroke-width", '1.5')
            .attr('stroke', '#8dc63f');
      
            svg.selectAll('.line')
          .data([data2])
          .join('path')
            .attr('d', d => generateScaledLine(d))
            .attr('fill', 'none')
            .attr("stroke-width", '1.5')
            .attr('stroke', '#2436e7');

    }, [data]);
  return (
    <div className='year-chart-container'>
      <svg ref={svgRef}  ></svg>
    </div>
  )
}

export default YearLineChart

