import React, { useState, useRef, useEffect } from 'react'
import * as d3 from 'd3'
import { select } from 'd3';
import './SelectedYearLineChart.css'
import axios from 'axios'
import moment from 'moment';

function SelectedYearLineChart({chart, setChart, selectedStock}) {
    // const [data] = useState([10,20,30,40,30,50,10,20,30,40,30,50]);
    const [yearData, setYearData] = useState([])

    const todaysDate = moment().format("YYYY-MM-DD");
    const yearRange = moment().subtract(365, 'days').format("YYYY-MM-DD")
    useEffect(() => {
    
      axios
        .get(`https://api.polygon.io/v2/aggs/ticker/${selectedStock}/range/1/minute/${yearRange}/${todaysDate}?adjusted=true&sort=asc&limit=120&apiKey=KxWufj3XvOHL844CeJUPh5DQFp_5F06n`)
        .then(response => 
         { 
           console.log(response.data.results);
          // setDayOpenData(Object.values(response.data.data.items).map((v) => (v.open)));
          setYearData(response.data.results);
          }
          
          );
    }, []);
    const data = yearData.map(i => i.c);
    

   
    const svgRef = useRef();

    useEffect(() => {
        const w = document.body.clientWidth
        const h = 244;
        const min = Math.min(...data) ;
        const max = Math.max(...data) ;
        const svg = d3.select(svgRef.current)
          .attr('width', w)
          .attr('height', h)
          .style('background', '#FFFFFF')
          .style('margin-top', '0');

        const xScale = d3.scaleLinear()
          .domain([0, data.length - 1])
          .range([0, w]);
        const yScale = d3.scaleLinear()
          .domain([min, max])
          .range([h, 0 ]);
        const generateScaledLine = d3.line()
          .x((d, i) => xScale(i))
          .y(yScale);
         


        svg.selectAll('.line')
          .data([data])
          .join('path')
            .attr('d', d => generateScaledLine(d))
            .attr('fill', 'none')
            .attr("stroke-width", '1.5')
            .attr('stroke', '#8dc63f');
      
           

    }, [data]);
  return (
    <div className='year-chart-container'>
    <svg ref={svgRef}  shapeRendering="geometricPrecision" ></svg>

    </div>
  )
}

export default SelectedYearLineChart

