import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FacebookSharpIcon from '@mui/icons-material/FacebookSharp';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import DylanHeadshot from '../Media/Headshots/Dylan_Welch.jpg'
import SimonHeadshot from '../Media/Headshots/Simon_Lawrence.jpg'
import BettyHeadshot from '../Media/Headshots/Betty_Murphy.jpg'
import { useNavigate } from 'react-router-dom';
import Axios from 'axios'

import './Blume3.css'

import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().max(32).required(),
  username: yup.string().max(32).required('Required Field'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null]),
}).required();




function Blume3() {

  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
      }
  });

  const navigate = useNavigate();

  const registerUser = (data) => {
    Axios.post('https://blume-server.herokuapp.com/register',{
      email: data.email,
      password: data.password,
      username: data.username,

      
    }).then((response) => {
        console.log(response);
        navigate('/homepage');
        window.scroll(0,0);
    });
    };


  const onSubmit = (data) => {
    registerUser(data)
    // console.log(data);
    
    };
   

  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
     <div className='main-3'>
     
      <div className='left-side-3'>
      <div className='logo-text-3'>BLUME</div>
      <div className='left-content-3'>

      <form onSubmit={handleSubmit(onSubmit)}>

     
        
    
      <div className='text-field-73'>
      <Controller
      
                rules={{ required: true }}
                name='username'
                control={control}
                render={({ field }) => <TextField 
                sx={{ width: '30vw' }}
                InputLabelProps={{ style: { marginLeft: '10px',fontSize: '.75em',   fontFamily: 'graphik-regular', color:  '#cccccc'} }} 
                inputProps={{ autoComplete: 'off', style: { color:  'black'}  }}
                id="standard-basic" 
                label="Username"
                variant="standard"
                type="username"
                // name='username'
                className='textField'
                {...field} />}
                // control={control}
              />
               
            </div>

             <div className='text-field-73'>

              <Controller
                rules={{ required: true }}
                name='email'
                control={control}
                render={({ field }) => 
                <TextField 
                sx={{ width: '30vw' }}
                InputLabelProps={{ style: { marginLeft: '10px',fontSize: '.75em', fontFamily: 'graphik-regular', color:  '#cccccc'} }} 
                inputProps={{ autoComplete: 'off' }}
                id="standard-basic" 
                variant="standard"
                label="Email"
                type="email"
                // name='email'
                className='text-field'
                 {...field} />}
                // control={control}
              />
                
            </div>
          
            <div className='text-field-73'>

              <Controller
                rules={{ required: true }}
                control={control}
                name='password'
                render={({ field }) =>  <FormControl sx={{ width: '30vw' }} variant="standard">
                <InputLabel sx={{ marginLeft: '10px',fontSize: '.75em', fontFamily: 'graphik-regular', color:  '#cccccc' }} htmlFor="standard-adornment-password">Create Password</InputLabel>
                <Input
                
                  id="standard-adornment-password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  {...field} />
              </FormControl>}
                
                // control={control}
              />
     
            </div>

            <div className='text-field-73'>

              <Controller
                rules={{ required: true }}
                control={control}
                name='confirmPassword'
                render={({ field }) =>
                <FormControl sx={{ width: '30vw' }} variant="standard">
                <InputLabel sx={{ marginLeft: '10px',fontSize: '.75em', fontFamily: 'graphik-regular', color:  '#cccccc' }} htmlFor="standard-adornment-password">Re-enter Password</InputLabel>
                <Input
                  id="standard-adornment-password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.confirmPassword}
                  onChange={handleChange('password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  {...field} />
              </FormControl>
                }
                

                
              />
                <button style={{width: "30vw", height: '6vh', marginTop: '3vh', marginBottom: '3vh'}} type='submit' className='button2' onClick={() => { window.scroll(0,0);}} >Get Started</button>
            
            </div>


  

   <div className='link-container-73'>
   <a onClick={() => {window.scroll(0,0);}} className='accredited-investor-link-7' href="/15">Are you an accredited investor? Apply here</a>
   </div>
 
   <div className='or-container-73'>
   <hr></hr> OR <hr></hr>
   </div>
   <div className='sign-up-container-73'>
     <div className='sign-up-items-73'><button className='sign-up-with-button-73'><div className='button-icon-73'><FacebookSharpIcon sx={{fontSize: '1.5em'}}/></div><div style={{  marginRight: 'auto' }} className='button-text-73'>Sign Up Facebook</div></button></div>
     <div className='sign-up-items-73'><button className='sign-up-with-button-73'><div className='button-icon-73'><AppleIcon sx={{fontSize: '1.5em'}}/></div><div style={{ marginRight: 'auto'}} className='button-text-73'>Sign Up Apple</div></button></div>
     <div style={{ marginBottom: '4vh'}} className='sign-up-items-73'><button className='sign-up-with-button-73'><div className='button-icon-73'><GoogleIcon sx={{fontSize: '1.5em'}}/></div><div style={{ marginRight: 'auto'}} className='button-text-73'>Sign Up Google</div></button></div>
     
   </div>

        </form> 

       
     
     
        
         
        </div>
        
      </div>

      <div className='right-side-3'>
      <div className='right-content-3'>
        <div className='right-text-3'>Expertise at your fingertips</div>
        <div className='investor-profile-3'>

          
            <img src={DylanHeadshot} className='profile-photo-3'/>
         

          <div className='profile-text-3'>
            <div className='profile-name-3'>Dylan Welch</div>
            <div className='profile-stats-3'>ROI: 38% Risk B</div>
            <div className='profile-bio-3'>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.</div>
            <div className='profile-tags-3'>#crypto #tech #growth #biotech</div>
          </div>
        </div>
        <div className='investor-profile-3'>

        <img src={SimonHeadshot} className='profile-photo-3'/>

          <div className='profile-text-3'>
            <div className='profile-name-3'>Simon Lawrence</div>
            <div className='profile-stats-3'>ROI: 38% Risk B</div>
            <div className='profile-bio-3'>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.</div>
            <div className='profile-tags-3'>#crypto #tech #growth #biotech</div>
          </div>
        </div>
        <div className='investor-profile-3'>

        <img src={BettyHeadshot} className='profile-photo-3'/>

          <div className='profile-text-3'>
            <div className='profile-name-3'>Betty Murphy</div>
            <div className='profile-stats-3'>ROI: 38% Risk B</div>
            <div className='profile-bio-3'>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.</div>
            <div className='profile-tags-3'>#crypto #tech #growth #biotech</div>
          </div>
        </div>

      </div>
      </div>

    </div>
      
    </>
  )
}

export default Blume3
