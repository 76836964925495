import React, { useState, useRef, useEffect } from 'react'
import * as d3 from 'd3'
import { select } from 'd3';
import './SelectedWeekLineChart.css'
import axios from 'axios'
import moment from 'moment';

function SelectedWeekLineChart({chart, setChart, selectedStock}) {
    
  const [WeekOpenData, setWeekOpenData] = useState([])
  const [weekData, setWeekData] = useState([])

  const todaysDate = moment().format("YYYY-MM-DD");
  const weekRange = moment().subtract(8, 'days').format("YYYY-MM-DD")

  // useEffect(() => {
    
  //   axios
  //     .get(`https://mboum-finance.p.rapidapi.com/hi/history/?symbol=${selectedStock}&interval=30m&diffandsplits=true`, {
  //       headers: {
  //         'X-RapidAPI-Host': 'mboum-finance.p.rapidapi.com',
  //         'X-RapidAPI-Key': '4e86c4f998mshe715399d2696ee1p1e5fdajsn3676c26d56a4'
    
  //       }
  //     })
  //     .then(response => 
  //      { 
  //        console.log(response.data);
  //       // setWeekOpenData(Object.values(response.data.data.items).map((v) => (v.open)));
  //       setWeekData(Object.values(response.data.items).map((v) =>  ({open : v.open, date : v.date})));
  //       }
        
  //       );
  // }, []);
  useEffect(() => {
    
    axios
      .get(`https://api.polygon.io/v2/aggs/ticker/${selectedStock}/range/3/minute/${weekRange}/${todaysDate}?adjusted=true&sort=asc&limit=120&apiKey=KxWufj3XvOHL844CeJUPh5DQFp_5F06n`)
      .then(response => 
       { 
         console.log(response.data.results);
        // setDayOpenData(Object.values(response.data.data.items).map((v) => (v.open)));
        setWeekData(response.data.results);
        }
        
        );
  }, []);


  // const data = weekData.filter(i => i.date > moment().subtract(8, 'days').format("DD-MM-YYYY")).map(i => i.open);
  const data = weekData.map(i => i.c);
  // .map(i => i.open)

    // const [data] =  useState([4446.59,
    // 4397.45,
    // 4412.53,
    // 4488.28,
    // 4500.21,
    // 4481.15,
    // 4525.12,
    // 4582.64,
    // 4545.86,
    // 4530.41,
    // 4602.45,
    // 4631.6,
    // 4575.52,
    // 4357.86,
    // 4262.45,
    // 4173.11])

   
    const svgRef = useRef();

    useEffect(() => {
        const w = document.body.clientWidth
        const h = 244;
        const min = Math.min(...data) ;
        const max = Math.max(...data) ;
        const svg = d3.select(svgRef.current)
          .attr('width', w)
          .attr('height', h)
          .style('background', '#FFFFFF')
          .style('margin-top', '0');

        const xScale = d3.scaleLinear()
          .domain([0, data.length - 1])
          .range([0, w]);
        const yScale = d3.scaleLinear()
          .domain([min, max])
          .range([h, 0 ]);
        const generateScaledLine = d3.line()
          .x((d, i) => xScale(i))
          .y(yScale);
         
        

        svg.selectAll('.line')
          .data([data])
          .join('path')
            .attr('d', d => generateScaledLine(d))
            .attr('fill', 'none')
            .attr("stroke-width", '1.5')
            .attr("id", "drop-shadow")
            .attr('stroke', '#8dc63f');
      
          

    }, [data]);
  return (
    <div className='year-chart-container'>
     
    
    <svg ref={svgRef}  shapeRendering="geometricPrecision" ></svg>

    </div>
  )
}

export default SelectedWeekLineChart

