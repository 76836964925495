import React from 'react'
import ChartSelect from '../Charts/ChartSelect'
import Navbar from '../Navbar/Navbar'
import { fakeInvestors } from '../FakeData/FakeInvestorData'
import './MyInvestorsProfile.css'
import MyInvestorsIndustryComponent from './MyInvestorsCharts/MyInvestorsIndustryComponent'
import MyInvestorsIndustryDonutChart from './MyInvestorsCharts/MyInvestorsIndustryDonutChart'
import BackArrow from '../Media/arrow_back_rounded.svg'

function MyInvestorsProfile({currentInvestor, selectedInvestor, setSelectedInvestor, ToggleInvestorPage}) {



  // var colorScale = d3.colorScale.ordinal().domain(["a", "b", "c", "d"])
  // .range(["#714595", "#D5D609", "#0055A4", "#EF561D" ]);

  // const types = currentInvestor.map(a => a.type);
  // const uniqueTypes = [...new Set(types)];
  // const sortedTypes = uniqueTypes.sort(function(a, b){return b- a});
 
      const investorStocksTypes = currentInvestor.map((investor) => investor.stocks.map(stock => stock.type));
      const investorStocks = currentInvestor.map((investor) => investor.stocks.map(stock => stock.company));
      // const stockTypes = investorStocks.map((stock) => stock.type);
      const uniqueTypes = [...new Set(investorStocksTypes[0])];
      

  return ( <>

    <div className='main-investor'>
    {/* <Navbar /> */}
    
      {currentInvestor.map((investor, index) => (

        <>
   
    
      <div key={index} className='background-investor'>
      
      
      <img  className='back-arrow-myinvestors' style={{ height: '2em' }} src={BackArrow} onClick={() =>{setSelectedInvestor('');ToggleInvestorPage(); }} />
{/* <button onClick={() => {console.log(currentInvestor)}}>printer</button> */}
      {/* {uniqueTypes.map((type) => (
        <div>type:{type}</div>
      ))} */}
      {/* {investorStocks.stocks.map((stock) => (
        <div>name{stock.company}</div>
      ))}
        */}
      
      <div className='user-profile-investor'>
        <img src={(investor.photo)} className='user-photo-investor'/>
        <div className='user-name-investor'>{investor.name}{investor.type}</div>
        <div className='user-bio-investor'>
          {investor.bio}
        </div>
       
      </div>
      <div className='chart-key-investor'><div className='chart-key-blue-ball'></div>Simon’s Investments</div>
      <div className='chart-key-sp500'><div className='chart-key-green-ball'></div>S&P 500</div>
      </div>

      <div className='chart-investor'>
        
        
        <ChartSelect />
      </div>
      
      <div className='strategy-investor'>
        <div className='strategy-big-text-investor'>Strategy</div>
        <div className='strategy-text-investor'>
          {investor.strategy}
        </div>
        <hr className='strategy-divider-investor'/>
      </div>
      <div className='risk-chart-investor'>
        <div className='main-chart-investor'>
        <MyInvestorsIndustryDonutChart currentInvestor={currentInvestor} uniqueTypes={uniqueTypes} />
        </div>
      </div>
      <MyInvestorsIndustryComponent currentInvestor={currentInvestor} />


      </>


  ))} 
    </div>
    </>)
}

export default MyInvestorsProfile
