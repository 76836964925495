import React from 'react'
import './Blume2.css'
import cloudLogo from '../Media/Cloud.svg';
import { BrowserRouter as Router, Link, Link as RouterLink } from 'react-router-dom'
var hand_left = require('../Media/hand_left.jpg')


function Blume2() {
  return (
    <>
    <div className='main-2'>
    <div className='blume-text-logo-2'>BLUME</div>
      <div className='left-side-2'>  
      <img className='left-image-2' src={ hand_left } />
      </div>

      <div className='right-side-2'>
        <div className='right-content-2'>
          <div className='logo-box-2'>
            <img className='cloud-logo-2' src={cloudLogo} alt="cloud-logo"/>
          </div>
        
          <div className='large-text-2'>Streamline Your Portfolio for Success</div>
          <div className='small-text-2'>Maintain autonomy and manage your investments like an expert. With BLUME, you can get live access to the portfolios of financial professionals and updates when they change. Sign up to get access to your first free verified investor.</div>
          <Link className='text-link' to='/websignup1' component={RouterLink}>
          <button onClick={() => {window.scroll(0,0);}} className='button-1'>Grow with Blume</button>
        </Link>
        
          
        
        </div>
      </div>

    </div>
      
    </>
  )
}

export default Blume2
