import React, {useState} from 'react'
import './Dropdown.css';

function IndustryDropdown({industry, setIndustry}) {

const [isActive, setIsActive] = useState(false);
const industrys = ['Crypto', 'Blue Chip', 'Utilities', 'Tech', 'Biotech', 'Real Estate']


  return (
    <div className='dropdown-leaderboard'>
      {isActive ? 
       <div className='dropdown-button-active-leaderboard' onClick={(e) =>
        setIsActive(!isActive)}>
        Select Industry <div className='dropdown-arrow-active-leaderboard'></div>
        </div>
      :
      <div className='dropdown-button-selected-leaderboard' onClick={(e) =>
        setIsActive(!isActive)}>
        {industry}<div className='dropdown-arrow-leaderboard'></div>
        </div>
       }
      
      {isActive && (
        <div className="dropdown-content-leaderboard">
          {industrys.map(industry => (
            <div key={industrys.industry}
            onClick={e => {
              setIndustry(industry)
              setIsActive(false)
            }} 
            className="dropdown-item-leaderboard">{industry}</div>
          ))}
        
        
      </div>
      )}
      
      
    </div>
  )
}

export default IndustryDropdown
