import React from 'react'
import ChartSelect from '../Charts/ChartSelect'
import IndustryDonutChart from '../IndustryComponents/IndustryDonutChart'
import IndustryComponent from '../IndustryComponents/IndustryComponent.js'
import Navbar from '../Navbar/Navbar'
import './VerifiedInvestor.css'

function VerifiedInvestor() {



  // var colorScale = d3.colorScale.ordinal().domain(["a", "b", "c", "d"])
  // .range(["#714595", "#D5D609", "#0055A4", "#EF561D" ]);

  return ( <>

    <div className='main-investor'>
      
      <Navbar />
      <div className='background-investor'>
      <div className='user-profile-investor'>
        <div className='user-photo-investor'></div>
        <div className='user-name-investor'>Simon Lawrence</div>
        <div className='user-bio-investor'>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. …
        </div>
       
      </div>
      <div className='chart-key-investor'><div className='chart-key-blue-ball'></div>Simon’s Investments</div>
      <div className='chart-key-sp500'><div className='chart-key-green-ball'></div>S&P 500</div>
      </div>

      <div className='chart-investor'>
        
        
        <ChartSelect />
      </div>
      
      <div className='strategy-investor'>
        <div className='strategy-big-text-investor'>Strategy</div>
        <div className='strategy-text-investor'>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis par. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis par Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis par …
        </div>
        <hr className='strategy-divider-investor'/>
      </div>
      <div className='risk-chart-investor'>
        <div className='main-chart-investor'>
        <IndustryDonutChart />
        </div>
      </div>
      <IndustryComponent />
      <IndustryComponent />
      <IndustryComponent />
      <IndustryComponent />
    </div>
    </>)
}

export default VerifiedInvestor
