import React from 'react'
import './IndustryComponent.css'

function IndustryComponent() {
  const indy1 = 50;
  const indy2 = 8;
  const indy3 = 20;
  const indy4 = 10;
  const indy5 = 12;

  return (
    <>
    <div className='industry-container'>
      <div style={{ background: '#714595' }} className='industry-color-circle'></div>
      <div className='industry-content'>
        <div className='industry-text'>
          <div className='industry-name'>Industry 1</div>
          <div className='industry-percentage'>{indy1}%</div>
        </div>
        <div className='industry-percentage-track'>
          <div style={{ width: `${indy1}%`, background: '#714595'  }} className='industry-percentage-bar'></div>
        </div>
      </div>
    </div>

    <div className='industry-container'>
      <div style={{ background: '#3FBFE6' }}  className='industry-color-circle'></div>
      <div className='industry-content'>
        <div className='industry-text'>
          <div className='industry-name'>Industry 2</div>
          <div className='industry-percentage'>{indy2}%</div>
        </div>
        <div className='industry-percentage-track'>
          <div style={{ width: `${indy2}%`, background: '#3FBFE6' }} className='industry-percentage-bar'></div>
        </div>
      </div>
    </div>

    <div className='industry-container'>
      <div style={{ background: '#EF561D' }}  className='industry-color-circle'></div>
      <div className='industry-content'>
        <div className='industry-text'>
          <div className='industry-name'>Industry 3</div>
          <div className='industry-percentage'>{indy3}%</div>
        </div>
        <div className='industry-percentage-track'>
          <div style={{ width: `${indy3}%`, background: '#EF561D' }} className='industry-percentage-bar'></div>
        </div>
      </div>
    </div>

    <div className='industry-container'>
      <div style={{ background: '#0055A4' }}  className='industry-color-circle'></div>
      <div className='industry-content'>
        <div className='industry-text'>
          <div className='industry-name'>Industry 4</div>
          <div className='industry-percentage'>{indy4}%</div>
        </div>
        <div className='industry-percentage-track'>
          <div style={{ width: `${indy4}%`, background: '#0055A4' }} className='industry-percentage-bar'></div>
        </div>
      </div>
    </div>

    <div className='industry-container'>
      <div style={{ background: '#D5D609' }}  className='industry-color-circle'></div>
      <div className='industry-content'>
        <div className='industry-text'>
          <div className='industry-name'>Industry 5</div>
          <div className='industry-percentage'>{indy5}%</div>
        </div>
        <div className='industry-percentage-track'>
          <div style={{ width: `${indy5}%`, background: '#D5D609' }} className='industry-percentage-bar'></div>
        </div>
      </div>
    </div>
    </>
    
  )
}

export default IndustryComponent