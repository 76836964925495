import React from 'react'
import { BrowserRouter as Router, Link, Link as RouterLink } from 'react-router-dom'
import './Blume6.css'
import cloudLogo from '../Media/Cloud.svg'

function Blume6() {
  return (
    // <div className='main-6'>

    //   <div className='logo-6'>

    //   </div>

    //   <div className='large-text-6'>
    //   Streamline Your Portfolio for Success
    //   </div>

    //   <div className='small-text-6'>
    //   Maintain autonomy and manage your investments like an expert. With BLUME, you can get live access to the portfolios of financial professionals and updates when they change. Sign up to get access to your first free verified investor.
    //   </div>

    //   <div className='button-6'>
    //     <button className='button2'>Grow with Blume</button>
    //   </div>

    //     <div className='nav-container-6'>

    //     <div className='nav-dot-left-6'>

    //     </div>
    //     <div className='nav-dot-right-6'>

    //     </div>
    //   </div>

        
    // </div>
    <>
    <div className='main-6'>

   
    <div className='content-6'>
      
    <img style={{ height: '15%', overflow: 'visable', marginLeft: '25%', marginBottom: '20px' }} className='cloud-logo' src={cloudLogo} alt="cloud-logo"/>
      
     
    
      <div className='large-text-6'>Streamline Your Portfolio for Success</div>
      <div className='small-text-6'>Maintain autonomy and <br/>manage your investments like <br/>an expert. With BLUME, you can <br/>get live access to the portfolios <br/>of financial professionals and <br/>updates when they change. Sign up to get access to your <br/>first free verified investor.</div>
      
      <div className='button-container-6'>
        <Link className='text-link' to='/7' component={RouterLink}>
          <button className='button2'>Grow with Blume</button>
        </Link>
        
      </div>
         <div className='nav-container-6'>

        <div className='nav-dot-right-6'></div>
        <div className='nav-dot-left-6'></div>

      </div>
    </div>
  </div>
  </>
  )
}

export default Blume6

