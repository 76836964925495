import React, { createContext, useState, useContext } from 'react'
import Dialog from '@mui/material/Dialog';
import './ToggleFilterButton.css'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterIndustry from './FilterIndustry';


function ToggleFilterButton() {

    const [isOpen, setIsopen] = useState(false);

    const ToggleSidebar = () => {
        isOpen === true ? setIsopen(false) : setIsopen(true);
    }
    // const [open, setOpen] = React.useState(false);

// const handleClickOpen = () => {
//   setOpen(true);
// };

// const handleClose = () => {
//   setOpen(false);
// };


  return (
    <>
   
   <div className='filter-button' cursor={'pointer'} onClick={ToggleSidebar}>
         <FilterAltIcon sx={{ color: '#CCCCCC'}}/>
      </div>
          
        <div className={`filter-menu ${isOpen == true ? 'active' : ''}`}>
{/*                  
      <div  style={{ marginLeft: 250, marginTop: 10 }}className='exit-button-menu' onClick={ToggleSidebar}>+</div> */}
                  
                      
      <FilterIndustry />
     </div>
  {/* <div className={`sidebar-overlay ${isOpen == true ? 'active' : ''}`} onClick={ToggleSidebar}></div> */}
     
                    
     

    </>
  )
}

export default ToggleFilterButton