import React, { useState } from 'react'
import './Blume15.css';
import blumeLogo from '../Media/BlumeLockup.svg'
import axios from 'axios';
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import YearDropdown15 from './YearDropdown15';
import TextField from '@mui/material/TextField';
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  username: yup.string().max(50).required('Required Field'),
  ssn: yup.string().max(50).required('Required Field'),
  facebook: yup.string().max(50).required('Required Field'),
  twitter: yup.string().max(50).required('Required Field'),
  instagram: yup.string().max(50).required('Required Field'),
  linkedin: yup.string().max(50).required('Required Field'),
}).required();



export default function Blume15() {

  const [year, setYear] = useState('The Year You Were Born');

  const { control, handleSubmit, register, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
        username: '',
        email: '',
        ssn: '',
        facebook: '',
        twitter: '',
        instagram: '',
        linkedin: '',
      
      }
  });


  

  const onSubmit = (data) => {
      console.log(
        data.username,
        data.ssn,
        data.facebook,
        year
        );
  };
  return (
    <>
    <div className='main-15'>
      <form style={{margin: 0, padding:0}}onSubmit={handleSubmit(onSubmit)}>

      
        <img src={blumeLogo}/>
      


      <div className='text-15' style={{ textAlign: 'center', marginBottom: 39 }}>Investor Application</div>
      
      <div className='upload-file-15'>
        <YearDropdown15 year={year} setYear={setYear}/>
      </div>
      

            <div className='text-field-15'>
              <Controller
                rules={{ required: true }}
                name='username'
                control={control}
                render={({ field }) => <TextField 
                sx={{ width: '350px' }}
                InputLabelProps={{ style: { marginLeft: '10px',fontSize: 14, fontFamily: 'graphik-regular', color:  '#cccccc'} }} 
                inputProps={{ autoComplete: 'off' }}
                id="standard-basic" 
                label="Username"
                variant="standard"
                type="username"
                // name='username'
                className='textField'
                {...field} />}
                // control={control}
              />
               
            </div>

            <div className='text-field-15'>
              <Controller
      
                rules={{ required: true }}
                name='email'
                control={control}
                render={({ field }) => <TextField 
                sx={{ width: '350px' }}
                InputLabelProps={{ style: { marginLeft: '10px',fontSize: 14, fontFamily: 'graphik-regular', color:  '#cccccc'} }} 
                inputProps={{ autoComplete: 'off' }}
                id="standard-basic" 
                label="Email Address"
                variant="standard"
                type="email"
                // name='username'
                className='textField'
                {...field} />}
                // control={control}
              />
               
            </div>

            <div className='text-field-15'>
              <Controller
      
                rules={{ required: true }}
                name='ssn'
                control={control}
                render={({ field }) => <TextField 
                sx={{  width: '350px' }}
                InputLabelProps={{ style: { marginLeft: '10px',fontSize: 14,  fontFamily: 'graphik-regular', color:  '#cccccc'} }} 
                inputProps={{ autoComplete: 'off', maxLength: 4, }}
                id="standard-basic" 
                label="Last 4 Digits of your Social"
                variant="standard"
                type="ssn"
                // name='username'
                className='textField'
                {...field} />}
                // control={control}
              />
               
            </div>

      <div className='upload-file-15'>
        
      <label for='file-upload' className='upload-button-15'>
        <div className='button-text-15'>Upload PDF Resume</div>
        <div className='button-icon-15'>+</div>
      </label>

        <input id='file-upload' style={{ display: 'none' }} type="file"/>
        
      </div>
          
      
      <div className='text-15' style={{ marginTop: 39, marginBottom: 39 }}>Share your social accounts with us.</div>

      <div className='social-accounts-15'>Facebook</div>
      <div className='regTextFields'>
        <Controller
      
          rules={{ required: true }}
          name='facebook'
          control={control}
          render={({ field }) => <TextField 
          sx={{ width: '350px' }}
          InputLabelProps={{ style: { marginLeft: '10px',fontSize: 14, fontFamily: 'graphik-regular', color:  '#cccccc'} }} 
          inputProps={{ autoComplete: 'off' }}
          id="standard-basic" 
          label="Username"
          variant="standard"
          type="facebook"
          className='textField'
          {...field} />}
          />
      </div>

      <div className='social-accounts-15'>Twitter</div>
      <div className='regTextFields'>
        <Controller
      
          rules={{ required: true }}
          name='twitter'
          control={control}
          render={({ field }) => <TextField 
          sx={{ width: '350px' }}
          InputLabelProps={{ style: { marginLeft: '10px',fontSize: 14, fontFamily: 'graphik-regular', color:  '#cccccc'} }} 
          inputProps={{ autoComplete: 'off' }}
          id="standard-basic" 
          label="Username"
          variant="standard"
          type="twitter"
          className='textField'
          {...field} />}
          />
      </div>

      <div className='social-accounts-15'>Instagram</div>
      <div className='regTextFields'>
        <Controller
      
          rules={{ required: true }}
          name='instagram'
          control={control}
          render={({ field }) => <TextField 
          sx={{ width: '350px' }}
          InputLabelProps={{ style: { marginLeft: '10px',fontSize: 14, fontFamily: 'graphik-regular', color:  '#cccccc'} }} 
          inputProps={{ autoComplete: 'off' }}
          id="standard-basic" 
          label="Username"
          variant="standard"
          type="instagram"
          className='textField'
          {...field} />}
          />
      </div>

      <div className='social-accounts-15'>LinkedIn</div>
      <div className='regTextFields'>
        <Controller
      
          rules={{ required: true }}
          name='linkedin'
          control={control}
          render={({ field }) => <TextField 
          sx={{ width: '350px' }}
          InputLabelProps={{ style: { marginLeft: '10px',fontSize: 14, fontFamily: 'graphik-regular', color:  '#cccccc'} }} 
          inputProps={{ autoComplete: 'off' }}
          id="standard-basic" 
          label="Username"
          variant="standard"
          type="LinkedIn"
          className='textField'
          {...field} />}
          />
      </div>


      <div className='submit-15'>
        <button type='submit'
          id='submit' style={{ marginTop: 50 }}className='button2'> Send Application</button>
      </div>
      <div className='TOS-text-15'> 
      By continuing, you agree to accept our <br/>Privacy Policy & Terms of Service.
      </div>
      </form>

      </div>
    </> 
  )
}
