import * as React from 'react';
import { useState, useMemo } from "react";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Height, Verified } from "@mui/icons-material";

import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import Blume2 from './Blume2/Blume2';
import Blume3 from './Blume3/Blume3';
import Blume5 from './Blume5/Blume5';
import Blume6 from './Blume6/Blume6';
import Blume7 from './Blume7/Blume7';
import Blume8 from './Blume8/Blume8';
import Blume11 from './Blume11/Blume11';
import Blume15 from './Blume15/Blume15';
import Blume17 from './Blume17/Blume17';
import Homepage from './Homepage/Homepage';
import VerifiedInvestor from './VerifiedInvestor/VerifiedInvestor';
import BrowseInvestors from './BrowseInvestors/BrowseInvestors';
import LandingPage from './LandingPage/LandingPage';

import MyInvestors from './MyInvestors/MyInvestors';
import BrowseStocks from './BrowseStocks/BrowseStocks';
import StockPage from './BrowseStocks/StockPage';

import MyStocks from './MyStocks/MyStocks';
import Inbox from './Inbox/Inbox';
import Login from './Login/Login';
import TestApi from './Test charts/TestApi';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import TOS from './TOS/TOS';
import EULA from './EULA/EULA';


function App() {
  const [isLoading, setIsLoading] = useState(false);
  const getData = async () => {
    setIsLoading(true);
    const response = await fetch(`/api/holdings/`, { method: "GET" });
    const data = await response.json();
    console.log(data)
    // if (data.error != null) {
    //   console.log(error)
    //   setError(data.error);
    //   setIsLoading(false);
     
    //   return;
    // }
    // console.log(data)
    // // setTransformedData(transformInvestmentsData(data)); // transform data into proper format for each individual product
    // if (data.pdf != null) {
    //   setPdf(data.pdf);
    //   console.log(data)
    
    // }
    // setShowTable(true);
    // setIsLoading(false);
  };

  const [selectedStock, setSelectedStock] = useState(null)
  const stockValue = useMemo(() => ({ selectedStock, setSelectedStock }), [ selectedStock, setSelectedStock]);
  return (
    <>
      {/* <button onClick={getData}>sdfsdf</button> */}
      <Router>
    
      <Routes>
      
        <Route 
          exact 
          path='/login' 
          element={
            <Login />
          }
        /> 

        <Route 
          exact 
          path='/websignup' 
          element={
            <Blume2 />
          }
        /> 
          <Route 
          exact 
          path='/websignup1' 
          element={
            <Blume3 />
          }
        /> 

         <Route 
          exact 
          path='/' 
          element={
            <LandingPage />
          }
        /> 
          <Route 
          exact 
          path='/6' 
          element={
            <Blume6 />
          }
        /> 

          <Route 
          exact 
          path='/7' 
          element={
            <Blume7 />
          }
        />    
        <Route 
          exact 
          path='/8' 
          element={
            <Blume8 />
          }
        />    <Route 
          exact 
          path='/11' 
          element={
            <Blume11 />
          }
        />  
        
        <Route 
          exact 
          path='/15' 
          element={
            <Blume15 />
          }
        /> 
         <Route 
          exact 
          path='/17' 
          element={
            <Blume17 />
          }
        /> 

        <Route 
          exact 
          path='/verifiedinvestor' 
          element={
            <VerifiedInvestor />
          }
        /> 
         <Route 
          exact 
          path='/homepage' 
          element={
            <Homepage />
          }
        /> 

        <Route 
          exact 
          path='/browseinvestors' 
          element={
            <BrowseInvestors />
          }
        /> 
       
        <Route 
          exact 
          path='/myinvestors' 
          element={
            <MyInvestors />
          }
        /> 

        <Route 
          exact 
          path='/browsestocks' 
          element={
            <BrowseStocks />
            
          }
        /> 

        <Route 
          exact 
          path='/stock' 
          element={
            <StockPage />
          }
        /> 
         <Route 
          exact 
          path='/mystocks' 
          element={
            <MyStocks />
          }
        /> 
        <Route 
          exact 
          path='/notifications' 
          element={
            <Inbox/>
          }
        /> 

          <Route 
          exact 
          path='/testapi' 
          element={
            <TestApi/>
          }
        /> 
   <Route 
          exact 
          path='/privacypolicy' 
          element={
            <PrivacyPolicy/>
          }
        /> 
        <Route 
          exact 
          path='/termsofservice' 
          element={
            <TOS/>
          }
        />
        <Route 
          exact 
          path='/useragreement' 
          element={
            <EULA/>
          }
        />
        

      </Routes>
     
  </Router>


    </>
  );
}

export default App;
