import React, {useState} from 'react'
import SelectedDayLineChart from './SelectedDayLineChart'
import SelectedWeekLineChart from './SelectedWeekLineChart'
import SelectedMonthLineChart from './SelectedMonthLineChart'
import SelectedYearLineChart from './SelectedYearLineChart'
import SelectedAllLineChart from './SelectedAllLineChart'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

function SelectedChartSelect({selectedStock}) {
  const [chart, setChart] = useState('week')








  return (
    <>
   
    {/* <TabPanel value={value} index={0}>
        <SelectedDayLineChart selectedStock={selectedStock} chart={chart} setChart={setChart} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SelectedWeekLineChart selectedStock={selectedStock} chart={chart} setChart={setChart}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SelectedMonthLineChart selectedStock={selectedStock} chart={chart} setChart={setChart}/>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <SelectedYearLineChart selectedStock={selectedStock} chart={chart} setChart={setChart}/>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <SelectedAllLineChart selectedStock={selectedStock} chart={chart} setChart={setChart}/>
      </TabPanel>


     <div style={{ width: '100vw', marginTop: '-1vh',height: '10vh'  }}>
        <StyledTabs sx={{ height: '2vh' }} fontFamily="graphik-medium" fontSize=".75em" textColor='secondary'
  indicatorColor='secondary' centered value={value} onChange={handleChange} aria-label="basic tabs example">
          <StyledTab sx={{ color: '#CCCCCC', fontFamily: "graphik-medium", fontSize: '.75em', margin: 0}}  label="Day" {...a11yProps(0)} />
          <StyledTab sx={{ color: '#CCCCCC', fontFamily: "graphik-medium", fontSize: '.75em', margin: 0}} label="Week" {...a11yProps(1)} />
          <StyledTab sx={{ color: '#CCCCCC', fontFamily: "graphik-medium", fontSize: '.75em', margin: 0}}  label="Month" {...a11yProps(2)} />
          <StyledTab sx={{ color: '#CCCCCC', fontFamily: "graphik-medium", fontSize: '.75em', margin: 0}}  label="Year" {...a11yProps(3)} />
          <StyledTab sx={{ color: '#CCCCCC', fontFamily: "graphik-medium", fontSize: '.75em', margin: 0}}  label="All" {...a11yProps(4)} />
        </StyledTabs>
        <hr style={{ backgroundColor: '#CCCCCC' }} className='test-hr'/>
     </div>
  */}
   <div style={{ width: '100vw' }}>
   <BasicTabs selectedStock={selectedStock} />
   </div>
    </>
  )
}

export default SelectedChartSelect


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div >
          {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#2436E7',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
   
   
    minWidth: '20vw',
    
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#2436E7',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#2436E7',
    },
  }),
);

 function BasicTabs({selectedStock}) {
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [chart, setChart] = useState('week')

  
  

  
  return (
    <div>
    
      <TabPanel value={value} index={0}>
        <SelectedDayLineChart selectedStock={selectedStock} chart={chart} setChart={setChart} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SelectedWeekLineChart selectedStock={selectedStock} chart={chart} setChart={setChart}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SelectedMonthLineChart selectedStock={selectedStock} chart={chart} setChart={setChart}/>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <SelectedYearLineChart selectedStock={selectedStock} chart={chart} setChart={setChart}/>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <SelectedAllLineChart selectedStock={selectedStock} chart={chart} setChart={setChart}/>
      </TabPanel>


     
      <div style={{ width: '100vw', marginTop: '-1vh',height: '10vh'  }}>
        <StyledTabs sx={{ height: '2vh' }} fontFamily="graphik-medium" fontSize=".75em" textColor='secondary'
  indicatorColor='secondary' centered value={value} onChange={handleChange} aria-label="basic tabs example">
          <StyledTab sx={{ color: '#CCCCCC', fontFamily: "graphik-medium", fontSize: '.75em', margin: 0}}  label="Day" {...a11yProps(0)} />
          <StyledTab sx={{ color: '#CCCCCC', fontFamily: "graphik-medium", fontSize: '.75em', margin: 0}} label="Week" {...a11yProps(1)} />
          <StyledTab sx={{ color: '#CCCCCC', fontFamily: "graphik-medium", fontSize: '.75em', margin: 0}}  label="Month" {...a11yProps(2)} />
          <StyledTab sx={{ color: '#CCCCCC', fontFamily: "graphik-medium", fontSize: '.75em', margin: 0}}  label="Year" {...a11yProps(3)} />
          <StyledTab sx={{ color: '#CCCCCC', fontFamily: "graphik-medium", fontSize: '.75em', margin: 0}}  label="All" {...a11yProps(4)} />
        </StyledTabs>
        <hr style={{ backgroundColor: '#CCCCCC' }} className='test-hr'/>
     </div>
     
      {/* { chart == 'day' && (
        <DayLineChartHP chart={chart} setChart={setChart} />
      )}
      { chart == 'week' && (
        <WeekLineChartHP chart={chart} setChart={setChart}/>
      )}
      { chart == 'month' && (
        <MonthLineChartHP chart={chart} setChart={setChart}/>
      )}
      { chart == 'year' && (
        <YearLineChartHP chart={chart} setChart={setChart}/>
      )}
      { chart == 'all' && (
        <AllLineChartHP chart={chart} setChart={setChart}/>
      )} */}
    </div>
  );
}
