import React, { useState } from 'react'
import ChartSelect from '../../Charts/ChartSelect'
import './SubscribeProfile.css'
import BackArrow from '../../Media/arrow_back_rounded.svg'

function SubscribeProfile({ToggleProfile, subComponent, setSubComponent, currentInvestor, }) {

  const [selectedInvestor, setSelectedInvestor] = useState('')


  return (
    <>
    <div className='main-investor'>
    {currentInvestor.map((investor, index) => (
      <div key={index} className='main-subscribeProfile'>
        
        <div className='background-subscribeProfile'>
        <img  className='back-arrow-myinvestors' style={{ height: '2em', marginRight: 'auto', marginTop: '2em'  }} src={BackArrow} onClick={() =>  {ToggleProfile(); window.scroll(0,0);}} />
     
  
        {/* <div className='user-profile-subscribeProfile'>

        <img src={investor.photo} className='user-photo-subscribeProfile'/>
          <div className='user-name-subscribeProfile'>{investor.name}</div>


          <div className='user-bio-subscribeProfile'>
            {investor.strategy}
          </div>
         
        </div> */}

        <div className='user-profile-investor'>
        <img src={(investor.photo)} className='user-photo-investor'/>
        <div className='user-name-investor'>{investor.name}{investor.type}</div>
        <div className='user-bio-investor'>
          {investor.bio}
        </div>
       
        </div>

        <div style={{ marginRight: 'auto' }} className='chart-key-investor'><div className='chart-key-blue-ball'></div>Simon’s Investments</div>
        <div style={{ marginRight: 'auto' }}  className='chart-key-sp500'><div className='chart-key-green-ball'></div>S&P 500</div>
        </div>
  
        <div className='chart-subscribeProfile'>
          
          
          <ChartSelect />
        </div>
        
        <div className='strategy-subscribeProfile'>
          <div className='strategy-big-text-investor'>Strategy</div>
          <div className='strategy-text-investor'>
            {investor.strategy}
          </div>
          <hr className='strategy-divider-subscribeProfile'/>
        </div>
  
        <div className='subscribe-button-subscribeProfile'>
          <button style={{ marginTop: '30px' }} className='button2'
          onClick={() => {setSubComponent('second'); window.scroll(0,0);}}
          >Subscribe</button>
          
        </div>
      </div>
    ))}
    </div>
    </>
  )
}

export default SubscribeProfile