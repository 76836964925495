import React, { useState, useEffect, useRef } from 'react'
import Axios from 'axios'
// import { fakeInvestors } from '/Users/liamwelling/Desktop/netlify1/src/FakeData/FakeInvestorData.js'
import './MyInvestorsIndustryComponent.css'
import * as d3 from 'd3';
import fillerLogo from '../../Media/stock_default.svg';

function MyInvestorsIndustryComponent({currentInvestor}) {

  const investorStocks = currentInvestor.map((investor) => investor.stocks.map(stock => stock));
  const sortedInvestorStocks = investorStocks.sort((a, b) => a.value - b.value).reverse();
  const [currentIndustry, setCurrentIndustry ] = useState(null)
  // const indy1 = investorStocks[0].filter((stocks => stocks.type === `${industry}`)).map((stock, index))
  const selectedIndustryChart = investorStocks[0].filter((stock => stock.type === `${currentIndustry}`));

   const donutData = currentInvestor.map((investor) => investor.stocks.map(stock => (
    { property: stock.type, value: stock.percentage, company: stock.company }
    )));
  
  const filteredData = donutData[0].filter((stock => stock.property === `${currentIndustry}`));
 
  const chartData = Array.from(filteredData.reduce(
    (m, {company, value}) => m.set(company, (m.get(company) || 0) + value), new Map
  ), ([company, value]) => ({company, value}));

  const industryData = Array.from(donutData[0].reduce(
    (m, {property, value}) => m.set(property, (m.get(property) || 0) + value), new Map
  ), ([property, value]) => ({property, value}));

const sortedIndustryData = industryData.sort((a, b) => a.value - b.value).reverse();


// items.sort(function (a, b) {
//   return a.value - b.value;
// });
  // const chartData = Array.from(donutData[0].reduce(
  //   (m, {property, value}) => m.set(property, (m.get(property) || 0) + value), new Map
  // ), ([property, value]) => ({property, value}));

 
 const [chartColor, setChartColor] = useState('red')

  const svgRef = useRef();
  useEffect(() => {
      const w = 185;
      const h = 185;
      const radius = w / 2;
      const svg = d3.select(svgRef.current)
        .attr('width', w)
        .attr('height', h)
        .style('overflow', 'visable');
       
        const linearScale = d3.scaleLinear()
        .domain([-1, .1, 1])
        .range([chartColor, "#CCCCCC", chartColor]);
        const colorArray = d3.range(chartData.length).map(d=>linearScale(d/(chartData.length - 1)));

        const ordinalScale = d3.scaleOrdinal()
	        .domain(chartData)
          .range(colorArray);   
        
      
      
      const formattedData = d3.pie().value(d => d.value)(chartData);
      const arcGenerator = d3.arc().innerRadius(65).outerRadius(radius);
      const color = d3.hsl(chartColor);
      const scale = d3.scaleSequential(t => d3.hsl(color.h, color.s * t, color.l) + "").domain([10, 1])
     
      svg.selectAll()
        .data(formattedData)
        .join('path')
          .attr('d', arcGenerator)
          // .attr("fill", d=>ordinalScale(d))
          .style('opacity', 1)
          .attr('fill',function(d) {
            if(chartData.length == 1) {
                return chartColor;
            } else {
                return ordinalScale(d);
            }
        });

}, [chartData]);



    const tester = investorStocks[0].filter((stock => stock.type === 'Consumer Staples'));
     const [stockInfo, setStockInfo] = useState([]);


    

    const [showMore, setShowMore] = useState(null);

  return (
    <>
    
    {sortedIndustryData.map((stock, index) => {
      const industry = `${stock.property}`;
      const colorName = industry.replace(/\s/g, '')
      var elementColor = 'grey';
      if (colorName == 'ConsumerStaples') {
        elementColor = '#00994c'
      } if (colorName == 'ETF') {
        elementColor ='#2e3192'
      } if (colorName == 'InformationTechnology') {
        elementColor = '#7184f4'
      } if (colorName == 'Financials') {
        elementColor = '#7cf473'
      } if (colorName == 'Energy') {
        elementColor = '#9e7eff'
      } if (colorName == 'Crypto') {
        elementColor = '#b3aecc'
      } if (colorName == 'Healthcare') {
        elementColor = '#a7d6a3'
      } if (colorName == 'ConsumerDiscresionary') {
        elementColor = '#da1c5c'
      } if (colorName == 'Materials') {
        elementColor = '#ed8700'
      } if (colorName == 'Utilities') {
        elementColor = '#f9ed32'
      } if (colorName == 'RealEstate') {
        elementColor = '#ef84ad'
      } if (colorName == 'REIT') {
        elementColor = '#bce0ed'
      } if (colorName == 'CommunicationServices') {
        elementColor = '#27aae1'
      } if (colorName == 'Industrials') {
        elementColor = '#6a8482'
      } 
      
      
    

      const getIcon = async (prop) => {
        const res = await Axios.get(`https://api.polygon.io/v3/reference/tickers/${prop}?apiKey=KxWufj3XvOHL844CeJUPh5DQFp_5F06n`)
        return(res.data.results.branding.icon_url)
      }


      
      return (
        <div key={index} className='industry-main' onClick={() => {setCurrentIndustry(industry); setShowMore(showMore => showMore === index ? null : index); setChartColor(elementColor)}}>


        <div className='industry-container'>
          
        <div style={{ background: elementColor }} className='industry-color-circle-myinvestors'></div>
        {/* <img src={fillerLogo} className='industry-color-circle-myinvestors'/> */}
        <div className='industry-content'>
          <div className='industry-text'>
            <div className='industry-name'>{stock.property}</div>
            <div className='industry-percentage'>{Math.round((stock.value + Number.EPSILON) * 100) / 100}%</div>
          </div>
          <div className='industry-percentage-track'>
            <div style={{ width: `${stock.value}%`, background: elementColor  }} className='industry-percentage-bar'></div>
          </div>
        </div>
      </div>
      

      {showMore === index 
        && (
          <>
   
      
        <div id={index} key={index} className='industry-main-selected'>

        
        <div className='industry-container-selected'>
       
        <div className='top-row-industry-container-selected'>
          <div style={{ background: elementColor }} className='industry-color-circle'></div>
          <div className='industry-content'>
            <div className='industry-text'>
              <div  className='industry-name'>{stock.property}</div>
              <div className='industry-percentage'>{Math.round((stock.value + Number.EPSILON) * 100) / 100}%</div>
            </div>
          </div>
        </div>

        <div className='chart-industry-container-selected'>
          <div className='industry-donut-chart-container'>
          <svg viewBox="-102 -50 204 100"   ref={svgRef}>
            <g>
            <text x="0%" y="-35%" textAnchor="middle" fontSize="18" fontFamily="graphik-medium">
            </text>
            </g>
          </svg>

          <div style={{ marginTop: '-175px', marginBottom: '70px', fontSize:"100px", fontFamily:"graphik-semibold" }}></div>
       </div>

        </div>
        <div className='company-row-industry-container-selected'>
        {investorStocks[0].filter((stocks => stocks.type === `${industry}`)).sort((a, b) => a.percentage - b.percentage).reverse().map((stock, index) => {

          
       
       return (
         

         <>
        <div key={index} className='bottom-row-industry-container-selected'>
        {/* <img src={fillerLogo} className='industry-color-circle-myinvestors'/> */}
        {(`${stock.icon}`.length > 10)
      ?
       <img src={stock.icon}  className='industry-color-circle-myinvestors' /> 
      :
        <img src={fillerLogo} className='industry-color-circle-myinvestors' />
        
      } 
          <div className='industry-content'>
            <div className='industry-text'>
              <div className='name-column-industry-container-selected'>
                <div className='industry-name-selected'>{stock.company}</div>
                <div className='industry-ticker-selected'>{stock.ticker}</div>
              </div>
            <div className='industry-percentage'>{Math.round((stock.percentage + Number.EPSILON) * 100) / 100}%</div>
            </div>
          </div>
        </div>
         </>
       )
      
     })}
      </div>
      </div>
     
 
      </div>
         
          </>
        )}
    
      </div>
      )
    })}

  
    </>
    
  )
}

export default MyInvestorsIndustryComponent


// const selectedIndustry = () => {
//   return ( 
//     <>
//     {chartData.map((stock, index) => {
//   const industry = `${stock.property}`;

//   return (
//     <div className='industry-main-selected'>

    
//     <div className='industry-container-selected'>
//       <div className='top-row-industry-container-selected'>
//     <div style={{ background: '#714595' }} className='industry-color-circle'></div>
//     <div className='industry-content'>
//       <div className='industry-text'>
//         <div  className='industry-name'>{stock.property}</div>
//         <div className='industry-percentage'>{stock.value}%</div>
//       </div>
    
//     </div>
//     </div>
//     <div className='chart-industry-container-selected'></div>
//     <div className='company-row-industry-container-selected'>
//     {investorStocks[0].filter((stocks => stocks.type === `${industry}`)).map((stock, index) => {
   


//    return (
//      <>
//      <div className='bottom-row-industry-container-selected'>
//     <div style={{ background: '#714595' }} className='industry-color-circle'></div>
//     <div className='industry-content'>
//       <div className='industry-text'>
//         <div className='name-column-industry-container-selected'>
//           <div className='industry-name-selected'>{stock.company}</div>
//           <div className='industry-ticker-selected'>{stock.ticker}</div>
//         </div>
        
//         <div className='industry-percentage'>{stock.percentage}%</div>
//       </div>
    
//     </div>
//     </div>
     
//      </>
//    )
  
//  })
 
//  }
//     </div>
//   </div>

//   </div>
//   )
// })}
//     </>
//   )
//  }
