import React from 'react'
import './Subscribe1.css'
import BackArrow from '../../Media/arrow_back_rounded.svg'

function Subscribe1({currentInvestor, subComponent, setSubComponent, ToggleProfile}) {

  const price = 25.00

  return (
    <>
    {currentInvestor.map((investor, index) => (
    <div key={index} className='main-subscribe1'>
   <div className='background-subscribeProfile'>
  
   <img  className='back-arrow-myinvestors' style={{ height: '2em', marginRight: 'auto', marginTop: '2em' }} src={BackArrow} onClick={() =>  {setSubComponent('main'); window.scroll(0,0);}} />
  



  <div className='user-profile-subscribeProfile'>
    <img src={investor.photo} className='user-photo-subscribeProfile'/>
    <div className='user-name-subscribeProfile'>{investor.name}</div>
   
   
  </div>
  
  </div>
      <div className='textbox-subscribe1'>
        
        {/* <div className='text-subscribe1'>{investor.bio}</div> */}
      </div>

      <div className='subscription-container-subscribe1'>
        <div className='subscription-type-subscribe1'>Premium <span></span></div>
        <div className='price-subscribe1'>${investor.price}<span style={{ fontFamily: 'graphik-regular' }}>/mo</span></div>
        <div className='subscription-conditions-subscribe1'>Full Access with Notifications</div>

      </div>
      
      
      
      <button 
        style={{ marginTop: '10vh' }} 
        className='button2'
        onClick={() => setSubComponent('third') }
         >
          Purchase Plan</button>
    </div>
    ))}
    </>
  )
}

export default Subscribe1