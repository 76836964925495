import React, {useState} from 'react'
import HomepageChartSelect from '../HomepageCharts/HomepageChartSelect'
import IndustryDonutChart from '../IndustryComponents/IndustryDonutChart'
import IndustryComponent from '../IndustryComponents/IndustryComponent.js'
import HomepageNavbar from '../Navbar/HomepageNavbar'
import LeaderboardSearch from './LeaderboardSearch/LeaderboardSearch.js'
import MostActive from './MostActive/MostActive.js'
import { fakeInvestors } from '../FakeData/FakeInvestorData'
import './Homepage.css'
import HomepageNews from './HomepageNews/HomepageNews'
import TestApi from '../Test charts/TestApi'
function Homepage() {

  const [stockProfile, setStockProfile] = useState(false)
  const [investorProfile, setInvestorProfile] = useState(false)


  return ( <>

    <div className='main-homepage'>
      <div className='chart-background-homepage'>
      <HomepageNavbar />
     
      
    
        <div className='blume-logo-homepage'>BLUME</div>
      <div className='chart-key-investor-homepage'><div className='chart-key-blue-ball-homepage'></div>Verified Investors</div>
      <div className='chart-key-sp500-homepage'><div className='chart-key-green-ball-homepage'></div>S&P 500</div>
     

      <div className='chart-homepage'>
        
        
        <TestApi/>
      </div>
      </div>
      {stockProfile == false && (
        <div  className='leaderboard-homepage'>
       <LeaderboardSearch investorProfile={investorProfile} setInvestorProfile={setInvestorProfile}/>
      </div>

      )}
      
      {investorProfile == false && (
  <div  className='most-active-homepage'>
  <MostActive stockProfile={stockProfile} setStockProfile={setStockProfile}/>
</div>
  )}
      
     

      {(investorProfile == false && stockProfile == false) && (
  <HomepageNews />
  )}
      

     
    </div>
    </>)
}

export default Homepage
