import React, {useState} from 'react'
import Navbar from '../Navbar/Navbar'
import BrowseIndustryDropdown from './BrowseIndustryDropdown'
import BrowseRiskDropdown from './BrowseRiskDropdown'
import BrowseYearDropdown from './BrowseYearDropdown'
import './BrowseInvestors.css'
import './FilterIndustry.css'
import FilterIndustry from './FilterIndustry'
import Menu from '../Menu/Menu'
import ToggleFilterButton from './ToggleFilterButton'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { fakeInvestors } from '../FakeData/FakeInvestorData'
import { BrowserRouter as Router, Link, Link as RouterLink } from 'react-router-dom'
import ChartSelect from '../Charts/ChartSelect'
import Subscribe1 from './Subscribe/Subscribe1.js'
import Subscribe2 from './Subscribe/Subscribe2.js'
import SubscribeProfile from './Subscribe/SubscribeProfile'


function BrowseInvestors() {

  const [risk, setRisk] = useState('Risk');
  const [industry, setIndustry] = useState('Industry');
  const [year, setYear] = useState('5 Year');
  const [crypto, setCrypto] = useState(false)
  const [energy, setEnergy] = useState(false)
  const [materials, setMaterials] = useState(false)
  const [industrials, setIndustrials] = useState(false)
  const [discretionary, setDiscretionary] = useState(false)
  const [staples, setStaples] = useState(false)
  const [health, setHealth] = useState(false)
  const [financials, setFinancials] = useState(false)
  const [technology, setTechnology] = useState(false)
  const [telecommunication, setTelecommunication] = useState(false)
  const [utilities, setUtilities] = useState(false)
  const [realEstate, setRealEstate] = useState(false)


  const [cryptoHashTag, setCryptoHashTag] = useState(false);
  const [blueChipHashTag, setBlueChipHashTag] = useState(false);
  const [utilitiesHashTag, setUtilitiesHashTag] = useState(false);
  const [techHashTag, setTechHashTag] = useState(false);
  const [biotechHashTag, setBiotechHashTag] = useState(false);
  const [realEstateHashTag, setRealEstateHashTag] = useState(false);



  const [list, setList] = useState(['1'])
  const addItem = () => {
    list.includes(2) ? list.filter(2) : list.push(2);
    
  }

  const cryptoFilter = () => {
    return(
      <div className='selected-categories-browse'>
      <div className='selected-category-browse'><span className='selected-category-name-browse'>Crypto</span> <div className='delete-category-browse' onClick={setCrypto(false)}>+</div></div>
 </div>);
  }




  const cryptoHandleOnChange = () => {
    setCrypto(!crypto);
  };

  const energyHandleOnChange = () => {
    setEnergy(!energy);
  };

  const materialsHandleOnChange = () => {
    setMaterials(!materials);
  };

  const industrialsHandleOnChange = () => {
    setIndustrials(!industrials);
  };

  const discretionaryHandleOnChange = () => {
    setDiscretionary(!discretionary);
  };

  const staplesHandleOnChange = () => {
    setStaples(!staples);
  };

  const healthHandleOnChange = () => {
    setHealth(!health);
  };

  const financialsHandleOnChange = () => {
    setFinancials(!financials);
  };

  const technologyHandleOnChange = () => {
    setTechnology(!technology);
  };

  const telecommunicationHandleOnChange = () => {
    setTelecommunication(!telecommunication);
  };

  const utilitiesHandleOnChange = () => {
    setUtilities(!utilities);
  };

  const realEstateHandleOnChange = () => {
    setRealEstate(!realEstate);
  };
  
  const [isOpen, setIsopen] = useState(false);
  const ToggleFilter = () => {
        isOpen === true ? setIsopen(false) : setIsopen(true);
    }

    const [openProfile, setOpenProfile] = useState(false);
    const ToggleProfile = () => {
      openProfile === true ? setOpenProfile(false) : setOpenProfile(true);
  }
  
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const [selectedInvestor, setSelectedInvestor] = useState('')


  const currentInvestor = fakeInvestors.filter(i => i.name == selectedInvestor);

  const [subComponent, setSubComponent] = useState('main');

  const top4 = fakeInvestors
  const [industryFilterList, setIndustryFilterList] = useState(['crypto']);

  let filteredInvestors = fakeInvestors.filter(i => i.risk == `${risk}` && i.industry == `${industry}` );

  if (risk == 'Risk' && industry == 'Industry') {
    filteredInvestors = fakeInvestors.filter((investor, index) => index < 10)
  } else {
    filteredInvestors = fakeInvestors.filter(i => i.risk.includes(`${risk}`) );
  }


  return (
    <div className='main-browse-investors'>
    
   
    
    <div className='main-browse-investors'>
    <Navbar/>
    
    <div className='big-text-browse-investors'>Browse Investors</div>
    
      <div className='select-filter-browse'>
        <div>
        <BrowseRiskDropdown risk={risk} setRisk={setRisk}/>
        </div>
        
        <div>
        <BrowseYearDropdown year={year} setYear={setYear}/>
        </div>

        <div className='filter-button' onClick={ToggleFilter}>
          Industry
          <FilterAltIcon sx={{ color: '#CCCCCC', marginLeft: 5 }}/>
        </div>
       </div>
       
    <div className='selected-categories-browse'>
    {crypto &&   
        <div className='selected-category-browse'>
          <span className='selected-category-name-browse'>Crypto</span> 
          <div className='delete-category-browse' onClick={() => {setCrypto(false)}}>+</div>
        </div>
      }
       {energy &&   
        <div className='selected-category-browse'>
          <span className='selected-category-name-browse'>Energy</span> 
          <div className='delete-category-browse' onClick={() => {setEnergy(false)}}>+</div>
        </div>
      }
       {materials &&   
        <div className='selected-category-browse'>
          <span className='selected-category-name-browse'>Materials</span> 
          <div className='delete-category-browse' onClick={() => {setMaterials(false)}}>+</div>
        </div>
      }
       {industrials &&   
        <div className='selected-category-browse'>
          <span className='selected-category-name-browse'>Industrials</span> 
          <div className='delete-category-browse' onClick={() => {setIndustrials(false)}}>+</div>
        </div>
      }
      {discretionary &&   
        <div className='selected-category-browse'>
          <span className='selected-category-name-browse'>Consumer Discretionary</span> 
          <div className='delete-category-browse' onClick={() => {setDiscretionary(false)}}>+</div>
        </div>
      }
       {staples &&   
        <div className='selected-category-browse'>
          <span className='selected-category-name-browse'>Consumer Staples</span> 
          <div className='delete-category-browse' onClick={() => {setStaples(false)}}>+</div>
        </div>
      }
       {health &&   
        <div className='selected-category-browse'>
          <span className='selected-category-name-browse'>Health Care</span> 
          <div className='delete-category-browse' onClick={() => {setHealth(false)}}>+</div>
        </div>
      }
       {financials &&   
        <div className='selected-category-browse'>
          <span className='selected-category-name-browse'>Financials</span> 
          <div className='delete-category-browse' onClick={() => {setFinancials(false)}}>+</div>
        </div>
      }
       {technology &&   
        <div className='selected-category-browse'>
          <span className='selected-category-name-browse'>Information Technology</span> 
          <div className='delete-category-browse' onClick={() => {setTechnology(false)}}>+</div>
        </div>
      }
       {telecommunication &&   
        <div className='selected-category-browse'>
          <span className='selected-category-name-browse'>Telecommunication Services</span> 
          <div className='delete-category-browse' onClick={() => {setTelecommunication(false)}}>+</div>
        </div>
      }
       {utilities &&   
        <div className='selected-category-browse'>
          <span className='selected-category-name-browse'>Utilities</span> 
          <div className='delete-category-browse' onClick={() => {setUtilities(false)}}>+</div>
        </div>
      }
       {realEstate &&   
        <div className='selected-category-browse'>
          <span className='selected-category-name-browse'>Real Estate</span> 
          <div className='delete-category-browse' onClick={() => {setRealEstate(false)}}>+</div>
        </div>
      }



      </div>

     
      {isOpen == true && (
    <div className={`filter-menu ${isOpen == true ? 'active' : ''}`}>
           
    
        <Navbar />
        <div className='large-text-row-filter'>
        <div className='large-text-filter'>Filter Categories</div>
        <div  className='exit-button-menu-filter' onClick={ToggleFilter}>+</div>
        </div>
        
        <div className='filter-list'>
          
          <div className='category-industry'>
            <div className='category-name-industry'>Crypto </div>
          
            <input
            className='filter-checkbox'
            type="checkbox"
            
            onChange={cryptoHandleOnChange}
           
            />
           
        </div>
     
          <hr className='category-divider' />
  
          <div className='category-industry'>
            <div className='category-name-industry'>Energy </div>
          
            <input
            className='filter-checkbox'
            type="checkbox"
           
            onChange={energyHandleOnChange}
            />
            
        </div>
        
          <hr className='category-divider' />
  
          <div className='category-industry'>
            <div className='category-name-industry'>Materials </div>
          
            <input
            className='filter-checkbox'
            type="checkbox"
            
            onChange={materialsHandleOnChange}
            />
            
        </div>
  
          <hr className='category-divider' />

          <div className='category-industry'>
            <div className='category-name-industry'>Industrials</div>
          
            <input
            className='filter-checkbox'
            type="checkbox"
            // checked={industrials}
            onChange={industrialsHandleOnChange}
            />
            
          </div>
  
          <hr className='category-divider' />

          <div className='category-industry'>
            <div className='category-name-industry'>Consumer Discretionary</div>
          
            <input
            className='filter-checkbox'
            type="checkbox"
          
            onChange={discretionaryHandleOnChange}
            />
            
          </div>
  
          <hr className='category-divider' />

          <div className='category-industry'>
            <div className='category-name-industry'>Consumer Staples</div>
          
            <input
            className='filter-checkbox'
            type="checkbox"
            // checked={staples}
            onChange={staplesHandleOnChange}
            />
            
          </div>
  
          <hr className='category-divider' />

          <div className='category-industry'>
            <div className='category-name-industry'>Health Care</div>
          
            <input
            className='filter-checkbox'
            type="checkbox"
            // checked={health}
            onChange={healthHandleOnChange}
            />
            
          </div>
  
          <hr className='category-divider' />

          <div className='category-industry'>
            <div className='category-name-industry'>Financials</div>
          
            <input
            className='filter-checkbox'
            type="checkbox"
            // checked={financials}
            onChange={financialsHandleOnChange}
            />
            
          </div>
  
          <hr className='category-divider' />

          <div className='category-industry'>
            <div className='category-name-industry'>Information Technology</div>
          
            <input
            className='filter-checkbox'
            type="checkbox"
            // checked={technology}
            onChange={technologyHandleOnChange}
            />
            
          </div>
  
          <hr className='category-divider' />

          <div className='category-industry'>
            <div className='category-name-industry'>Telecommunication Services</div>
          
            <input
            className='filter-checkbox'
            type="checkbox"
            // checked={telecommunication}
            onChange={telecommunicationHandleOnChange}
            />
            
          </div>
  
          <hr className='category-divider' />

          <div className='category-industry'>
            <div className='category-name-industry'>Utilities</div>
          
            <input
            className='filter-checkbox'
            type="checkbox"
            // checked={utilities}
            onChange={utilitiesHandleOnChange}
            />
            
          </div>
  
          <hr className='category-divider' />

          <div className='category-industry'>
            <div className='category-name-industry'>Real Estate</div>
          
            <input
            className='filter-checkbox'
            type="checkbox"
            // checked={realEstate}
            onChange={realEstateHandleOnChange}
            />
            
          </div>
  
          <hr className='category-divider' />
          
        </div>
        <div className='hashtag-filter'>
          <div className='large-text-filter'>Hashtags</div>
        </div>
        <div className='hashtag-container-filter'>
          <div className='hashtag-list-filter'>#crypto</div>
          <div className='hashtag-list-filter'>#tech</div>
          <div className='hashtag-list-filter'>#biotech</div>
          <div className='hashtag-list-filter'>#utilities</div>
          <div className='hashtag-list-filter'>#realestate</div>
          <div className='hashtag-list-filter'>#bluechip</div>
        </div>
        <div className='bottom-buttons-filter'>
          <button style={{ marginTop: 66,  }} className='button2' onClick={ToggleFilter} >Apply Filters</button>
          <div className='small-text-filter'>Reset Filters</div>
        </div>
        
        
   

  </div>
  )}
<div className={`sidebar-overlay ${isOpen == true ? 'active' : ''}`} onClick={ToggleFilter}></div>
  
        
   
      

      <div className='investor-container-browse'>


     
{filteredInvestors.map((investor, index) => (
  <div key={index} className='investor-profile-investors'
  onClick={() => {setSelectedInvestor(investor.name); ToggleProfile(); window.scroll(0,0);}}
  >

    
    <img src={(investor.photo)} className='profile-photo-investors'/>
  <div className='profile-text-leaderboard'>
  
    <div className='profile-name-leaderboard'>{investor.name}</div>
    <div className='profile-stats-leaderboard'>ROI: 38% Risk {investor.risk}</div>
    <div className='profile-bio-leaderboard'>{investor.bio?.length > 25 ?
    `${investor.bio.substring(0, 90)}...` : investor.bio
  }</div>
    <div className='profile-tags-leaderboard'>#crypto #tech #growth #biotech</div>
  </div>
</div>
))}

</div>



{openProfile == true && (
<div className={`filter-menu ${openProfile == true ? 'active' : ''}`}>



{subComponent == 'main' && (      

  <SubscribeProfile currentInvestor={currentInvestor} ToggleProfile={ToggleProfile} setSubComponent={setSubComponent}/>

  )}

  {subComponent == 'second' && (
    <Subscribe1 currentInvestor={currentInvestor} subComponent={subComponent} setSubComponent={setSubComponent} />
  )}
  

{subComponent == 'third' && (
    <Subscribe2 currentInvestor={currentInvestor} ToggleProfile={ToggleProfile} setSubComponent={setSubComponent}/>
  )}
    </div>
)}
    
    </div>
    </div>
  )
}

export default BrowseInvestors

