import React, { useState, useEffect } from 'react'
import './Inbox.css'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Article } from '@mui/icons-material';
import Navbar from '../Navbar/Navbar';
import Axios from 'axios'

function Inbox() {

 const fakeInboxUpdates = [
   {
     "name" : "Simon Lawrence",
     "photo": require('../Media/Headshots/Simon_Lawrence.jpg'),
     "notifications" : [
      
       {  
        "id" : 1,
         "message" : "Invested in Tesla",
         "time" : "Today 9:30 AM",
         "unread" : true
       },
       {
        "id" : 2,
        "message" : "Added Apple to portfolio",
        "time" : "3 days ago",
        "unread" : true
      }
     ]
    },
    {
      "name" : "Dylan Welch",
      "photo" : require('../Media/Headshots/Dylan_Welch.jpg'),
      "notifications" : [
        { 
          "id" : 3,
          "message" : "Invested in Apple",
          "time" : "Today 10:30 AM",
          "unread" : true
        },
        {
          "id" : 4,
         "message" : "Added Ford to portfolio",
         "time" : "2 days ago",
         "unread" : true
       }
      ]
     },
     {
      "name" : "Betty Murphy",
      "photo" : require('../Media/Headshots/Betty_Murphy.jpg'),
      "notifications" : [
        {
          "id" : 5,
          "message" : "Invested in Tesla",
          "time" : "Today 9:30 AM",
          "unread" : true
        },
      
      ]
     },
     {
      "name" : "James Gill",
      "photo" : require('../Media/Headshots/James_Gill.jpg'),
      "notifications" : [
        {
          "id" : 6,
          "message" : "Invested in Google",
          "time" : "Today 10:00 AM",
          "unread" : true
        },
        {
        "id" : 7,
         "message" : "Added Tesla to portfolio",
         "time" : "1 day ago",
         "unread" : true
       }
      ]
     },
 ]

 


 const [stockNews, setStockNews] = useState([]);
 useEffect(() => {
   Axios.get(`https://api.polygon.io/v2/reference/news?limit=5&apiKey=KxWufj3XvOHL844CeJUPh5DQFp_5F06n`).then(res => {
     console.log(res.data.results);
     setStockNews(res.data.results);
      
   });
 }, []);

  const [toggleContent, setToggleContent] = useState('updates');
  const [updates, setUpdates] = useState(fakeInboxUpdates)


  const unreadMessages = updates.map((update) => {
    return {...update, notifications: update.notifications.filter((notification) => notification.unread === true)}
  })

  const finalMessages =  unreadMessages.filter(update=> update.notifications.length > 0)

  const handleDeleteClick = (updateIndex, notificationIndex) => {
    const newUpdates = [...finalMessages];
    console.log(newUpdates[updateIndex].notifications[notificationIndex]);

    newUpdates[updateIndex].notifications[notificationIndex].unread = false;
    setUpdates(newUpdates);
  }
  



  const [showMore, setShowMore] = useState(null);
  
  const InvestorUpdates = () => {
     return (
    <>
    
    
    {finalMessages.map((update, index) => {
      const updateIndex = index;
      return (
      <>
      <div key={index} className='content-container-inbox'>
        <div onClick={() => setShowMore(showMore => showMore === index ? null : index)} className='updates-row-inbox'>
          <img src={update.photo} className='photo-updates-inbox' />
          <div className='name-updates-inbox'>{update.name}</div>
          <div className='icon-updates-inbox'>
            <div className='update-count-inbox'>{(update.notifications).length}</div>
            
            </div>
        </div>
        {showMore === index 
        && (
          <>
       
          {update.notifications.map((notification, index) => (

          notification.unread?
          <div key={index} className='update-dropdown-inbox'>
          <div className='dropdown-row-inbox'>
          <div className='leftside-dropdown-row-inbox'>
              <div className='time-inbox'>{notification.time}</div>
              <div className='message-inbox'>{notification.message}</div>
            </div>
            <div  onClick={() => {handleDeleteClick(updateIndex, index ); console.log(updateIndex); }} className='rightside-dropdown-row-inbox'><DeleteOutlineIcon sx={{ color: '#cccccc', cursor: 'pointer'}}/></div>
          </div>
          <hr className='category-divider-inbox' />
    


        </div>
        : null
        ))}
        </>
        )
        }
    
      
      </div>
      {/* <hr className='category-divider-inbox' /> */}
      </>
      )
      })}
    
    </>
  )
 }

 const StockNews = () => {
   return (
    <>
    {stockNews?.map((article, index) => (
        <div key={index} className='content-container-inbox'>

        <div className='news-container-inbox'>
          <div className='news-leftside-inbox'>
            <div className='large-text-news-inbox'>{article.title}</div>
            <div className='small-text-news-inbox'><p>{article.description?.length > 25 ?
    `${article.description.substring(0, 75)}...` : article.description
  }</p></div>
          </div>
          <img src={article.image_url} className='photo-news-inbox' />
        </div>
  
      </div>
    ))}
    </>
   )
 }

  return (
    <div className='main-inbox'>
      <Navbar />
      <div className='large-text-inbox'>My Inbox</div>
      <div className='select-inbox'>

      <div className="switcher">
      <input type="radio" name="balance" value="yin" id="yin" className="switcher__input switcher__input--yin" checked=""/>
      <label for="yin" className="switcher__label" onClick={() => {setToggleContent('updates') }} >Investor Updates</label>
      
      <input type="radio" name="balance" value="yang" id="yang" className="switcher__input switcher__input--yang"/>
      <label for="yang" className="switcher__label" onClick={() => {setToggleContent('news') }}>Stock News</label>
      
      <span className="switcher__toggle"></span>
    </div>


      </div>


    
      {toggleContent == 'updates' && (
        <InvestorUpdates /> 
      )} 

     {toggleContent == 'news' && (
       <StockNews />
      )}
      </div>

  )
}

export default Inbox