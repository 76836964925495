import React, {useState} from 'react'
import './BrowseInvestors.css';

function BrowseRiskDropdown({risk, setRisk}) {

const [isActive, setIsActive] = useState(false);
const risks = ['Risk','A', 'B', 'C', 'D' ]
const years = Array.from(Array(new Date().getFullYear() - 1900), (_, i) => (i + 1901).toString()).reverse();

  return (
    <div className='dropdown-browse'>
      {isActive ? 
       <div className='dropdown-button-active-browse' onClick={(e) =>
        setIsActive(!isActive)}>
        Select Risk <div className='dropdown-arrow-active-browse'></div>
        </div>
      :
      <div className='dropdown-button-selected-browse' onClick={(e) =>
        setIsActive(!isActive)}>
        {risk}<div className='dropdown-arrow-browse'></div>
        </div>
       }
      
      {isActive && (
        <div className="dropdown-content-browse">
          {risks.map(risk => (
            <div key={risks.risk}
            onClick={e => {
              setRisk(risk)
              setIsActive(false)
            }} 
            className="dropdown-item-browse">{risk}</div>
          ))}
        
        
      </div>
      )}
      
      
    </div>
  )
}

export default BrowseRiskDropdown
