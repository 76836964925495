import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FacebookSharpIcon from '@mui/icons-material/FacebookSharp';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import './Blume7.css'
import BlumeLogo from '../Media/BlumeLockup.svg'
import { Link, Navigate } from "react-router-dom";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { color } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios'
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import YearDropdown from '../Blume11/YearDropdown';
import HowDropdown from '../Blume11/HowDropdown.jsx';


const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().max(32).required(),
  username: yup.string().max(32).required('Required Field'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null]),
  save: yup.boolean().required(), 
  income: yup.boolean().required(), 
  outpace: yup.boolean().required(), 
  earn: yup.boolean().required(), 
}).required();


const Switch1 = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#C5C5C5' : '#2436E7',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#C5C5C5' : '#2436E7',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function Blume7() {

const [formToggle, setFormToggle] = useState('page1')

  const { control, handleSubmit, formState: { errors }, setValue, register  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        birthyear: '1',
        save: false,
        income: false,
        outpace: false,
        earn: false,
      }
  });

  const navigate = useNavigate();

  const registerUser = (data) => {
    Axios.post('https://blume-server.herokuapp.com/register',{
      email: data.email,
      password: data.password,
      crypto: crypto,
      username: data.username,
      birthyear: year,
      risk: risk,
      save: data.save,
      income: data.income,
      outpace: data.outpace,
      earn: data.earn,
      bluechip: blueChip,
      utilities: utilities,
      tech: tech,
      biotech: biotech,
      realestate: realEstate
      
    }).then((response) => {
        console.log(response);
        navigate('/homepage');
        window.scroll(0,0);
    });
    };


  const onSubmit = (data) => {
    
      // console.log(data);
      registerUser(data)
      // navigate('/11');
   
    
    };
   

  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };



////////

    const [year, setYear] = useState('The Year You Were Born');
    const [how, setHow] = useState('How Did You Hear About Us')

    const [crypto, setCrypto] = useState(false);
    const [blueChip, setBlueChip] = useState(false);
    const [utilities, setUtilities] = useState(false);
    const [tech, setTech] = useState(false);
    const [biotech, setBiotech] = useState(false);
    const [realEstate, setRealEstate] = useState(false);

    const [risk, setRisk] = useState('low');

    const LowRisk = () => {
      return ( <div className='low-risk-select-11'>
      <div className='low-risk-pill-11'>Low</div>
      <div className='low-risk-medium-11' onClick={() => setRisk('medium')}>Medium</div>
      <div className='low-thin-line-11'></div>
      <div className='low-risk-high-11'onClick={() => setRisk('high')}>High</div>
    </div>);
    }

    const MidRisk = () => {
      return ( 
      <div className='low-risk-select-11'>
      <div className='mid-risk-low-11' onClick={() => setRisk('low')}>Low</div>
      <div className='mid-risk-pill-11'>Medium</div>
      <div className='mid-risk-high-11' onClick={() => setRisk('high')}>High</div>
    </div>);
    }

    const HighRisk = () => {
      return(
      <div className='low-risk-select-11'>
              <div className='high-risk-low-11' onClick={() => setRisk('low')}>Low</div>
              <div className='high-thin-line-11'></div>
              <div className='high-risk-medium-11' onClick={() => setRisk('medium')}>Medium</div>
              <div className='high-risk-pill-11'>High</div>
            </div>);
    }
    

    const formPageToggle = () => {
      setFormToggle('page2')
    }





  return (
    <>
     <form style={{margin: 0, padding:0}} onSubmit={handleSubmit(onSubmit)}>
    {formToggle === 'page1' && (
      <div className='main-7'>
    
      
        

     
      
        <img src={BlumeLogo}   style={{
    
    resize: 'horizontal',
    overflow: 'hidden',
    width: '100vw',
    height: 'auto',
  }}/>
      
  

      <div className='small-text-7' style={{ textAlign: 'center', marginBottom: 30 }}>Create an account</div>
      <div style={{  marginBottom: 30 }} className='link-container-7'>
        <a onClick={() => {window.scroll(0,0);}} className='accredited-investor-link-7' href="/login"> Already have an account? </a>
        </div>
      <div className='text-field-7'>
      <Controller
      
                rules={{ required: true }}
                name='username'
                control={control}
                render={({ field }) => <TextField 
                sx={{ width: '350px' }}
                InputLabelProps={{ style: { marginLeft: '10px',fontSize: 14,   fontFamily: 'graphik-regular', color:  '#cccccc'} }} 
                inputProps={{ autoComplete: 'off', style: { color:  'black'}  }}
                id="standard-basic" 
                label="Username"
                variant="standard"
                type="username"
                // name='username'
                className='textField'
                {...field} />}
                // control={control}
              />
               
            </div>

             <div className='text-field-7'>

              <Controller
                rules={{ required: true }}
                name='email'
                control={control}
                render={({ field }) => 
                <TextField 
                sx={{ width: '350px' }}
                InputLabelProps={{ style: { marginLeft: '10px',fontSize: 14, fontFamily: 'graphik-regular', color:  '#cccccc'} }} 
                inputProps={{ autoComplete: 'off' }}
                id="standard-basic" 
                variant="standard"
                label="Email"
                type="email"
                // name='email'
                className='text-field'
                 {...field} />}
                // control={control}
              />
                
            </div>
          
            <div className='text-field-7'>

              <Controller
                rules={{ required: true }}
                control={control}
                name='password'
                render={({ field }) =>  <FormControl sx={{ width: '350px' }} variant="standard">
                <InputLabel sx={{ marginLeft: '10px',fontSize: 14, fontFamily: 'graphik-regular', color:  '#cccccc' }} htmlFor="standard-adornment-password">Create Password</InputLabel>
                <Input
                
                  id="standard-adornment-password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  {...field} />
              </FormControl>}
                
                // control={control}
              />
     
            </div>

            <div className='text-field-7'>

              <Controller
                rules={{ required: true }}
                control={control}
                name='confirmPassword'
                render={({ field }) =>
                <FormControl sx={{ width: '350px' }} variant="standard">
                <InputLabel sx={{ marginLeft: '10px',fontSize: 14, fontFamily: 'graphik-regular', color:  '#cccccc' }} htmlFor="standard-adornment-password">Re-enter Password</InputLabel>
                <Input
                  id="standard-adornment-password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.confirmPassword}
                  onChange={handleChange('password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  {...field} />
              </FormControl>
                }
                

                
              />
            
            </div>
        <div className='button-container-7'>
     
          <button type='submit' className='button2' onClick={() => {formPageToggle(); window.scroll(0,0);}} >Create Account</button>
        
        </div>

  

        <div className='link-container-7'>
        <a onClick={() => {window.scroll(0,0);}} className='accredited-investor-link-7' href="/15">Are you an accredited investor? Apply here</a>
        </div>
      
        <div className='or-container-7'>
        <hr></hr> OR <hr></hr>
        </div>
        <div className='sign-up-container-7'>
          <div className='sign-up-items-7'><button className='sign-up-with-button-7'><div className='button-icon-7'><FacebookSharpIcon sx={{fontSize: '35px'}}/></div><div style={{ marginRight: 103 }} className='button-text-7'>Sign Up Facebook</div></button></div>
          <div className='sign-up-items-7'><button className='sign-up-with-button-7'><div className='button-icon-7'><AppleIcon sx={{fontSize: '35px'}}/></div><div style={{ marginRight: 118 }} className='button-text-7'>Sign Up Apple</div></button></div>
          <div style={{ marginBottom: '4vh'}} className='sign-up-items-7'><button className='sign-up-with-button-7'><div className='button-icon-7'><GoogleIcon sx={{fontSize: '35px'}}/></div><div style={{ marginRight: 112 }} className='button-text-7'>Sign Up Google</div></button></div>
          
        </div>
        
         
        </div>
    )}




    
    {formToggle === 'page2' && (
      <div className='main-11'>
      {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <div className='content-11'>
          
           <div style={{ marginBottom: 24 }}className='large-text-11'>
          Just a few more questions to optimize your <span style={{ color: '#2436e7' }}>Blume</span> experience.
          </div> 
          <div className='form-content-11'>
          
          <div className='dropdown-container-7'>
            <YearDropdown  year={year} setYear={setYear}/>
          </div>
          
          
          <div style={{marginBottom: 21 }}className='label-text-11'>Risk Tolerance</div>
          <div className='dropdown-container-7'>
          {risk == 'low' && (
           <LowRisk />
         )}
         {risk == 'medium' && (
           <MidRisk />
         )} 
         {risk == 'high' && (
           <HighRisk />
         )}
          </div>
          
          <div className='label-text-11' style={{ marginBottom: '19px'}}>Investment Goals</div>
          <div className='goals-container-11'>
  
            <div className='goal-11'>
              <div className='goal-label-11'>Save for retirement</div>
              <div className='goal-switch-11'>
                
              <Controller
                name="save"
                control={control}
                defaultValue={false}
                sx={{ml: 0}}
                render={({ 
                field: { onChange, value },
                fieldState: { error, invalid }
                })  => (
                  <Switch1
                    value={value}
                    onChange={(event, value)=> onChange(value)}
                  />
                )}
              />
  
              </div>
            </div>
  
            <div className='goal-11'>
              <div className='goal-label-11'>Income for next large expenditure</div>
              <div className='goal-switch-11'>
               
              <Controller
                name="income"
                control={control}
                defaultValue={false}
                sx={{ml: 0}}
                render={({ 
                field: { onChange, value },
                fieldState: { error, invalid }
                })  => (
                  <Switch1
                    value={value}
                    onChange={(event, value)=> onChange(value)}
                  />
                )}
              />
  
              </div>
            </div>
  
            <div className='goal-11'>
              <div className='goal-label-11'>Outpace Inflation</div>
              <div className='goal-switch-11'>
                
              <Controller
                name="outpace"
                control={control}
                defaultValue={false}
                sx={{ml: 0}}
                render={({ 
                field: { onChange, value },
                fieldState: { error, invalid }
                })  => (
                  <Switch1
                    value={value}
                    onChange={(event, value)=> onChange(value)}
                  />
                )}
              />
  
              </div>
            </div>
  
            <div className='goal-11'>
              <div className='goal-label-11'>Earn higher returns on your money</div>
              <div className='goal-switch-11'>
  
              <Controller
                name="earn"
                control={control}
                defaultValue={false}
                sx={{ml: 0}}
                render={({ 
                field: { onChange, value },
                fieldState: { error, invalid }
                })  => (
                  <Switch1
                    value={value}
                    onChange={(event, value)=> onChange(value)}
                  />
                )}
              />
  
              </div>
            </div>
  
          </div>
  
          <div className='label-text-11' style={{ marginBottom: '21px'}}>Mainly Interested In</div>
          <div className='interest-container'>
  
            <div className='interest-row' style={{ marginBottom: '17px'}}>
              {crypto?
               <div className='selected-interest-11' onClick={e => {
                setCrypto(false)
              }} >Crypto</div>
              :
              <div className='default-interest-11'  onClick={e => {
                setCrypto(true)
              }} >Crypto</div>
              }
  
              {blueChip?
               <div className='selected-interest-11' onClick={e => {
                setBlueChip(false)
              }} >Blue Chip</div>
              :
              <div className='default-interest-11'  onClick={e => {
                setBlueChip(true)
              }} >Blue Chip</div>
              }
  
              {utilities?
               <div className='selected-interest-11' onClick={e => {
                setUtilities(false)
              }} >Utilities</div>
              :
              <div className='default-interest-11'  onClick={e => {
                setUtilities(true)
              }} >Utilities</div>
              }
              
            </div>
            <div className='interest-row'>
              {tech?
               <div className='selected-interest-11' onClick={e => {
                setTech(false)
              }} >Tech</div>
              :
              <div className='default-interest-11'  onClick={e => {
                setTech(true)
              }} >Tech</div>
              }
  
              {biotech?
               <div className='selected-interest-11' onClick={e => {
                setBiotech(false)
              }} >Biotech</div>
              :
              <div className='default-interest-11'  onClick={e => {
                setBiotech(true)
              }} >Biotech</div>
              }
  
              {realEstate?
               <div className='selected-interest-11' onClick={e => {
                setRealEstate(false)
              }} >Real Estate</div>
              :
              <div className='default-interest-11'  onClick={e => {
                setRealEstate(true)
              }} >Real Estate</div>
              }
              
            </div>
  
  
          </div>
          <div style={{ marginTop: 43, marginBottom: 35 }}>
            <div className='dropdown-container-7'>
              <HowDropdown how={how} setHow={setHow}/>
            </div>
          </div>
          
  
          <div className='submit-button-container-11'>
            <button type='submit'
            id='submit' className='button2'>Finish Account Setup</button>
          </div>
  
          </div>
  
        </div>
        {/* </form> */}
      </div>
    )}
        
     
   
        </form>
    </>
  )
}

export default Blume7
