import React, { useState, useEffect, useRef } from 'react'
// import { fakeInvestors } from 'FakeData/FakeInvestorData.js'
import * as d3 from 'd3';

import './MyInvestorsIndustryDonutChart.css'
function MyInvestorsIndustryDonutChart({currentInvestor, uniqueTypes}) {

  const [data] = useState([
    { property: '1', value: 50 },
    { property: '2', value: 8 },
    { property: '3', value: 20 },
    { property: '4', value: 10 },
    { property: '4', value: 12 },
    { property: '4', value: 12 },
    { property: '4', value: 12 },
   
  ]);
  const donutData = currentInvestor.map((investor) => investor.stocks.map(stock => (
    { property: stock.type, value: stock.percentage }
    )));

  const chartData = Array.from(donutData[0].reduce(
      (m, {property, value}) => m.set(property, (m.get(property) || 0) + value), new Map
    ), ([property, value]) => ({property, value}));

  const investorStocksTypes = currentInvestor.map((investor) => investor.stocks.map(stock => stock.type));
  const investorStocks = currentInvestor.map((investor) => investor.stocks.map(stock => stock.company));
  // const stockTypes = investorStocks.map((stock) => stock.type);
  // const uniqueTypes = [...new Set(investorStocksTypes[0])];
  // const singleData = donutData[0].reduce(function (acc, obj) { return acc + obj.property; }, 0);
  // var x_sum = donutData[0].reduce((acc, curr) => acc + curr.value, 0);


  const svgRef = useRef();
  useEffect(() => {
      const w = 304;
      const h = 304;
      const radius = w / 2;
      const svg = d3.select(svgRef.current)
        .attr('width', w)
        .attr('height', h)
        .style('overflow', 'visable');
       

      const formattedData = d3.pie().value(d => d.value)(chartData);
      const arcGenerator = d3.arc().innerRadius(130).outerRadius(radius);
      const color = d3.scaleOrdinal()
      .domain(['Consumer Staples', "ETF", "Information Technology", 'Financials', 'Energy', 'Crypto', 'Healthcare',  'Consumer Discresionary', 'Materials', 'Utilities', 'Real Estate', 'REIT', 'Communication Services', 'Industrials'])
      .range(["#00994c", "#2e3192","#7184f4","#7cf473","#9e7eff","#b3aecc","#a7d6a3","#da1c5c","#ed8700","#f9ed32","#ef84ad","#bce0ed","#27aae1","#6a8482" ]);

      svg.selectAll()
        .data(formattedData)
        .join('path')
          .attr('d', arcGenerator)
          .attr('fill',function(d){
            if (d.data.property == 'Consumer Staples'){
             return '#00994c';
            } if (d.data.property == "ETF"){
             return "#2e3192";
            } if (d.data.property == "Information Technology"){
             return "#7184f4";
            } if (d.data.property  == 'Financials') {
              return '#7cf473'
            } if (d.data.property == 'Energy') {
              return '#9e7eff'
            } if (d.data.property == 'Crypto') {
              return '#b3aecc'
            } if (d.data.property  == 'Healthcare') {
              return '#a7d6a3'
            } if (d.data.property  == 'Consumer Discresionary') {
              return '#da1c5c'
            } if (d.data.property  == 'Materials') {
              return '#ed8700'
            } if (d.data.property  == 'Utilities') {
              return '#f9ed32'
            } if (d.data.property  == 'Real Estate') {
              return '#ef84ad'
            } if (d.data.property == 'REIT') {
              return'#bce0ed'
            } if (d.data.property == 'Communication Services') {
              return '#27aae1'
            } if (d.data.property  == 'Industrials') {
              return '#6a8482'
            } 
        })
          .style('opacity', 1);
      // svg.selectAll()
      //   .data(formattedData)
      //   .join('text')
      //     .text(d => d.data.property)
      //     .attr('transform', d => `translate(${arcGenerator.centroid(d)})`)
      //     .style('text-anchor', 'middle');
}, [chartData]);


// function sumObjectsByKey(...objs) {
//   return objs.reduce((a, b) => {
//     for (let k in b) {
//       if (b.hasOwnProperty(k))
//         a[k] = (a[k] || 0) + b[k];
//     }
//     return a;
//   }, {});
// }


  return (
    <>
    {/* <button onClick={() => {console.log(donutData[0])}}>print</button> */}
    {/* <button onClick={() => {console.log(res)}}>print</button> */}
    {currentInvestor.map((investor, index) => (
    <div key={index} className='industry-donut-chart-container'>
      
   <svg viewBox="-152 -50 304 100"   ref={svgRef}>
     <g>
     <circle cx="0" cy="0" r="120" 
            stroke="#cccccc" 
            strokeWidth="1" 
            fill="none"
            />
    <text x="0%" y="-35%" textAnchor="middle" fontSize="18" fontFamily="graphik-medium">
    RISK
      </text>
    
    </g>
   </svg>
   <div style={{ marginTop: '-175px', marginBottom: '70px', fontSize:"100px", fontFamily:"graphik-semibold" }}>{investor.risk}</div>
    </div>
    ))}  
   </>
  )
}

export default MyInvestorsIndustryDonutChart


