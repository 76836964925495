import React, {useState} from 'react'
import Navbar from '../Navbar/Navbar'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PlusIcon from '../Media/blueplus_whitecirlce.svg'
import { fakeInvestors } from '../FakeData/FakeInvestorData'
import './MyInvestors.css'
import MyInvestorsProfile from './MyInvestorsProfile';
import Dialog from '@mui/material/Dialog';
import closeX from '../Media/closex.svg'

function MyInvestors() {

  const [investorList, setInvestorList] = useState(fakeInvestors)

  const [isOpen, setIsopen] = useState(false);

  const ToggleInvestorPage = () => {
      isOpen === true ? setIsopen(false) : setIsopen(true);
  }

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const [openProfile, setOpenProfile] = useState(false);
const [open, setOpen] = React.useState(false);
const [fullWidth, setFullWidth] = React.useState(true);
const handleClickOpen = () => {
setOpen(true);
};

const handleClose = () => {
setOpen(false);
};

 
const [deleteStock, setDeleteStock] = useState(null);
const ToggleDelete = () => {
  isOpen === true ? setIsopen(false) : setIsopen(true);
}

const handleDeleteClick = (investorName) => {
  const newInvestors = [...investorList];

  const newList = newInvestors.filter((investor) => investor.name !== investorName);
  
  setInvestorList(newList);
}

const MyInvestorsRow = () => {

  const ToggleDelete = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  }

  const [open, setOpen] = React.useState(false);
const [fullWidth, setFullWidth] = React.useState(true);
const handleClickOpen = () => {
setOpen(true);
};

  return (
    <>
    {investorList.map((investor, index) => (
      <div key={index} id={index} className='row-container-myinvestors'>

        {/* <div onClick={() => setDeleteStock(deleteStock => deleteStock === index ? null : index)} className='delete-dot-column-container'>
          <div className='delete-dot'></div>
          <div className='delete-dot'></div>
          <div className='delete-dot'></div>
        </div> */}

       
        <div  id={investor.name} style={{ cursor: 'pointer', transition: 0.5 }} className={` ${deleteStock === index ? 'container-myinvestors-active' : 'container-myinvestors'}`}
          onClick={() => {setSelectedInvestor(investor.name);  ToggleInvestorPage(); }}
        >


          
          <img src={(investor.photo)} className='color-circle-myinvestors-active'/>
          <div className='content-myinvestors'>
            <div className='name-myinvestors'>{investor.name}</div>
            <div className='text-myinvestors'>
              <div> ROI: 38%</div>
              <div> RISK {investor.risk}</div>
            </div>
         </div>

      </div>
        <div 
          onClick={() => { handleDeleteClick(investor.name);}} 
          className={`delete-button-container-mystocks ${deleteStock === index ? 'active' : ''}`}>
          {/* <div  className='exit-button-menu' ></div>           */}
      <DeleteOutlineIcon sx={{ color: 'white', fontSize: '35px'}} /> 
        </div>

     

      </div>  
    ))}
  </>

  )}


  const [selectedInvestor, setSelectedInvestor] = useState('')


  const currentInvestor = fakeInvestors.filter(i => i.name == selectedInvestor);
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

  const minSwipeDistance = 50 

  const onTouchStart = (e) => {
    setTouchEnd(null) 
    setTouchStart(e.targetTouches[0].clientX)
  }

const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

const onTouchEnd = (index) => {
  if (!touchStart || !touchEnd) return
  const distance = touchStart - touchEnd
  const isLeftSwipe = distance > minSwipeDistance
  const isRightSwipe = distance < -minSwipeDistance
  if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right')
//  console.log('swipe')
if (isLeftSwipe)  setDeleteStock(deleteStock => deleteStock === index ? null : index)
if (isRightSwipe)  setDeleteStock(null)


// ToggleDelete();
// setDeleteStock(deleteStock => deleteStock === index ? null : index)
}

const toggleDeleteButton = (index) => {
  setDeleteStock(deleteStock => deleteStock == index ? null : index)
}


  return (
    <>
    
    <div className='main-myinvestors'>
    <Navbar />
    
    <Dialog 
    PaperProps={{
      style: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    }} onClose={handleClose} open={open}>
      
      <div className='remove-dialog-mystocks'>
        <div onClick={() => {handleClose(); setDeleteStock(null);}} className='x-dialog-mystocks'> <img style={{ height: 60 }}src={closeX}/></div>
        <div className='large-text-dialog-mystocks'>Are you sure you <br/> want to remove <br/> {selectedInvestor}?</div>
        <div onClick={() => {handleDeleteClick(selectedInvestor); handleClose(); setDeleteStock(null); }}className='small-text-dialog-mystocks'>Yes please remove</div>
      </div>

    </Dialog>
    <div className='header-row-myinvestors'>
      
      <div className='large-text-myinvestors'>My Investors</div>
      <div> 
        {/* <img src={PlusIcon}/> */}
      </div>
    </div>
    
    
<div className='content-container-myinvestors'>

</div>
{/* <MyInvestorsRow /> */}
{investorList.map((investor, index) => (
      <div key={index} id={index} 
      onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={() => {onTouchEnd(index)}} 
      className='row-container-myinvestors' >
        {/* <button onClick={() => {setDeleteStock(deleteStock => deleteStock === index ? null : index)}}>delete</button> */}

        {/* <div onClick={() => setDeleteStock(deleteStock => deleteStock === index ? null : index)} className='delete-dot-column-container'>
          <div className='delete-dot'></div>
          <div className='delete-dot'></div>
          <div className='delete-dot'></div>
        </div> */}

       
        <div  id={investor.name} style={{ cursor: 'pointer', transition: 0.5 }} className={` ${deleteStock === index ? 'container-myinvestors-active' : 'container-myinvestors'}`}
          onClick={() => {setSelectedInvestor(investor.name); ToggleInvestorPage(); window.scroll(0,0);}}
          
        >


          
          <img src={(investor.photo)} className='color-circle-myinvestors-active'/>
          <div className='content-myinvestors'>
            <div className='name-myinvestors'>{investor.name}</div>
            <div className='text-myinvestors'>
              <div> ROI: 38%</div>
              <div> RISK {investor.risk}</div>
            </div>
         </div>

      </div>
        <div 
         onClick={() => {setSelectedInvestor(investor.name); handleClickOpen(); }}
          className={`delete-button-container-mystocks ${deleteStock === index ? 'active' : ''}`}>
          {/* <div  className='exit-button-menu' ></div>           */}
      <DeleteOutlineIcon sx={{ color: 'white', fontSize: '35px'}} /> 
        </div>

     {/* <button onClick={() => setDeleteStock(deleteStock => deleteStock === index ? null : index)}>delete</button> */}

      </div>  
    ))}



{isOpen == true && (
<div className={`filter-menu ${isOpen == true ? 'active' : ''}`}>



  <MyInvestorsProfile setSelectedInvestor={setSelectedInvestor} selectedInvestor={selectedInvestor} currentInvestor={currentInvestor} ToggleInvestorPage={ToggleInvestorPage}/>
    </div>
)}
    


    </div>
    </>
  )
}

export default MyInvestors