import React, { createContext, useState, useContext } from 'react'
import Dialog from '@mui/material/Dialog';
import './Menu.css'
import { BrowserRouter as Router, Link, Link as RouterLink } from 'react-router-dom'
import Menu from './Menu'
import FilterIndustry from '../BrowseInvestors/FilterIndustry';
import MenuIcon from '@mui/icons-material/Menu';
import headshot from '../Media/headshot.svg';

function WhiteMenuButton() {

    const [isOpen, setIsopen] = useState(false);

    const ToggleSidebar = () => {
        isOpen === true ? setIsopen(false) : setIsopen(true);
    }
    const [open, setOpen] = React.useState(false);

    const username = JSON.parse(localStorage.getItem('username'));
    const email = JSON.parse(localStorage.getItem('email'));

  return (
    <>

 <div className="container-fluid mt-3">
                
              
            <MenuIcon sx={{ color: 'white' }} cursor={'pointer'} onClick={ToggleSidebar}/>        
      {/* <div className='menu-icon' cursor={'pointer'} onClick={ToggleSidebar}>
        <div className='icon-bar'/>
        <div className='icon-bar'/>
        <div className='icon-bar'/>
      </div>
           */}
        <div className={`sidebar ${isOpen == true ? 'active' : ''}`}>
                    <div  style={{ marginLeft: '60vw', marginTop: 10 }}className='exit-button-menu' onClick={ToggleSidebar}>+</div>          
                    <div className='menu-main'>
        
         <img src={headshot} className='profile-photo-menu' />
        <div className='username-menu'>{username}</div>
        <div className='email-menu'>{email}</div>

        <div className='link-container-menu'>
          <Link className='text-link' to='/homepage' component={RouterLink} onClick={() => {window.scroll(0,0);}}>
            <div className='link-item-menu'>Home</div>
          </Link>

          <Link className='text-link' to='/browseinvestors' component={RouterLink} onClick={() => {window.scroll(0,0);}}>
            <div className='link-item-menu'>Browse Investors</div>
          </Link>
          <Link className='text-link' to='/browsestocks' component={RouterLink} onClick={() => {window.scroll(0,0);}}>
            <div className='link-item-menu'>Browse Stocks</div>
          </Link>
          <Link className='text-link' to='/myinvestors' component={RouterLink} onClick={() => {window.scroll(0,0);}}>
            <div className='link-item-menu'>My Investors</div>
          </Link>

          <Link className='text-link' to='/mystocks' component={RouterLink} onClick={() => {window.scroll(0,0);}}>
            <div className='link-item-menu'>My Stocks</div>
          </Link>

          <Link className='text-link' to='/notifications' component={RouterLink} onClick={() => {window.scroll(0,0);}}>
          <div className='link-item-menu'>Notifications</div>
          </Link>


          <Link
        className='text-link'
          component={RouterLink}
          to="/login">
          <div
          className='link-item-menu'
          onClick={() => localStorage.clear()} > 
              Sign Out
            </div>
          </Link>
        </div>

        {/* <div className='sign-out-menu'> Sign Out</div>  */}
      </div>
          </div>
                <div className={`sidebar-overlay ${isOpen == true ? 'active' : ''}`} onClick={ToggleSidebar}></div>
       </div>
     
    </>
  )
}

export default WhiteMenuButton