import React, { useState, useContext, useEffect } from 'react'
import Axios from 'axios'
import SelectedChartSelect from './StockCharts/SelectedChartSelect.js'
import moment from 'moment';
import axios from 'axios'
import backButton from '../Media/arrow_back_rounded.svg'
import fillerLogo from '../Media/stock_default.svg'

function MyStockPage({ ToggleStockPage, selectedStock, setSelectedStock  }) {


  const [stockInfo, setStockInfo] = useState([]);
 
  useEffect(() => {
    Axios.get(`https://api.polygon.io/v2/snapshot/locale/us/markets/stocks/tickers?tickers=${selectedStock}&apiKey=KxWufj3XvOHL844CeJUPh5DQFp_5F06n`).then(res => {
    console.log(res.data.tickers)
    // console.log('i fire once');
    setStockInfo(res.data.tickers);
       
    });
  }, []);

  function callStockNews(prop) {
  Axios.get(`/api/v1/ne/news/?symbol=${prop}&apikey=Tl0Ri3UppgujVzR62KsjU2hnaa5FwDZv4MOGgnLzTn7gFhJLpe8Eb4dyqY8P`).then(res => {

    setStockNews(res.data.data);
     
  });
};
const [stockNews, setStockNews] = useState([]);
useEffect(() => {
  Axios.get(`https://api.polygon.io/v2/reference/news?ticker=${selectedStock}&limit=3&apiKey=KxWufj3XvOHL844CeJUPh5DQFp_5F06n`).then(res => {
    console.log(res.data.results);
    setStockNews(res.data.results);
     
  });
}, []);


 const news = stockNews.item;
 const topThree = news?.filter((investor, index) => index < 3);

 const apiKey = '?apiKey=KxWufj3XvOHL844CeJUPh5DQFp_5F06n';

 const [stockIcon, setStockIcon] = useState([]);
 const [stockName, setStockName] = useState([]);
 const [stockAbout, setStockAbout] = useState([]);
 useEffect(() => {
   Axios.get(`https://api.polygon.io/v3/reference/tickers/${selectedStock}?apiKey=KxWufj3XvOHL844CeJUPh5DQFp_5F06n`).then(res => {
     
       setStockName(res.data.results.name);
       setStockIcon(res.data.results.branding.icon_url);
       setStockAbout(res.data.results.description);
      
   });
 }, []);


  return (
    <div className='main-stockpage'>
        
      <div className='upper-stockpage'>
    

<div className='back-button-container-stockpage'>
  <img src={backButton} alt='backButton' className='back-arrow-myinvestors' style={{ height: '2em' }}  onClick={() => {ToggleStockPage(); setSelectedStock(null);}} />  
</div>

{(`${stockIcon}`.length > 30)
      ?
       <img src={`${stockIcon}` + `${apiKey}`}  className='icon-stockpage' /> 
      :
        <img src={fillerLogo} className='icon-stockpage' />
        
      } 
{/* <img src={`${stockIcon}` + `${apiKey}`}  className='icon-stockpage' /> */}

      </div>
      
      {stockInfo?.map((stock, index) => {
        return (
<div key={index} className='lower-stockpage'>
      <div className='name-stockpage'>{stockName}</div>
      <div style={ stock.todaysChange  > 0 ? { color:'green'} : {color : 'red'} } className='todays-movement-stockpage'>{stock.todaysChange ?.toFixed(2)} ({stock.todaysChangePerc?.toFixed(2)})% Today</div>
      <div className='movement-time-stockpage'>{moment().format('LLL')}</div>
      <div className='about-stockpage'>{stockAbout.length > 200 ? `${stockAbout.substring(0, 200)}...` : stockAbout}</div>

      
     
      <SelectedChartSelect selectedStock={selectedStock}/>

    </div>    
        )
      }
       
        )}  
<div  className='content-container-inbox'>
{stockNews?.map((article, index) => {
  return (
    <a style={{ textDecoration : 'none', color: 'black' }} target="_blank" href={article.article_url}>
    <div key={index} className='news-container-inbox' >
  <div className='news-leftside-inbox'>
    <div className='large-text-news-inbox'>{article.title}</div>
    <div className='small-text-news-inbox'><p>{article.description?.length > 25 ?
    `${article.description.substring(0, 75)}...` : article.description
  }</p></div>
  </div>
  <img  src={article.image_url} className='photo-news-inbox' />
</div>
</a>
  )
})}


</div>
    

    </div>
  )
}

export default MyStockPage