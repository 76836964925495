import React, { useState, useRef, useEffect } from 'react'
import * as d3 from 'd3'
import { select } from 'd3';
import './SelectedMonthLineChart.css'
import axios from 'axios'
import moment from 'moment';


function SelectedMonthLineChart({chart, setChart, selectedStock}) {
  const [dayOpenData, setDayOpenData] = useState([])
  const [monthData, setMonthData] = useState([])

  const todaysDate = moment().format("YYYY-MM-DD");
  const monthRange = moment().subtract(30, 'days').format("YYYY-MM-DD")
  // useEffect(() => {
    
  //   axios
  //     .get(`https://mboum-finance.p.rapidapi.com/hi/history/?symbol=${selectedStock}&interval=1h&diffandsplits=true`, {
  //       headers: {
  //         'X-RapidAPI-Host': 'mboum-finance.p.rapidapi.com',
  //         'X-RapidAPI-Key': '4e86c4f998mshe715399d2696ee1p1e5fdajsn3676c26d56a4'
    
  //       }
  //     })
  //     .then(response => 
  //      { 
  //        console.log(response.data);
  //       setMonthData(Object.values(response.data.items).map((v) => (v.open)));
  //       // setDayData(Object.values(response.data.data.items).map((v) =>  ({open : v.open, date : v.date})));
  //       }
        
  //       );
  // }, []);

  useEffect(() => {
    
    axios
      .get(`https://api.polygon.io/v2/aggs/ticker/${selectedStock}/range/3/minute/${monthRange}/${todaysDate}?adjusted=true&sort=asc&limit=120&apiKey=KxWufj3XvOHL844CeJUPh5DQFp_5F06n`)
      .then(response => 
       { 
         console.log(response.data.results);
        // setDayOpenData(Object.values(response.data.data.items).map((v) => (v.open)));
        setMonthData(response.data.results);
        }
        
        );
  }, []);
  
    const data =  monthData.map(i => i.c);
   
    const svgRef = useRef();

    useEffect(() => {
        const w = document.body.clientWidth
        const h = 244;
        const min = Math.min(...data);
        const max = Math.max(...data);
        const svg = d3.select(svgRef.current)
          .attr('width', w)
          .attr('height', h)
          .style('background', '#FFFFFF')
          .style('margin-top', '0');

        const xScale = d3.scaleLinear()
          .domain([0, data.length - 1])
          .range([0, w]);
        const yScale = d3.scaleLinear()
          .domain([min, max])
          .range([h, 0 ]);
        const generateScaledLine = d3.line()
          .x((d, i) => xScale(i))
          .y(yScale);
         


        svg.selectAll('.line')
          .data([data])
          .join('path')
            .attr('d', d => generateScaledLine(d))
            .attr('fill', 'none')
            .attr("stroke-width", '1.5')
            .attr('stroke', '#8dc63f');
      
           

    }, [data]);
  return (
    <div className='year-chart-container'>
      
    <svg ref={svgRef}  shapeRendering="geometricPrecision" ></svg>

    </div>
  )
}

export default SelectedMonthLineChart

