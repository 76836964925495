import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import './MostActive.css'
import MyStockPage from '../../MyStocks/MyStockPage.js';



function MostActive({stockProfile, setStockProfile}) {

  const [ selectedStock, setSelectedStock ]= useState(null)

  const [isOpen, setIsopen] = useState(false);
  const ToggleStockPage = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
    stockProfile === false ? setStockProfile(true) : setStockProfile(false);
}



  const [stock1Info, setStock1Info] = useState([]);
  useEffect(() => {
    Axios.get(`https://api.polygon.io/v2/snapshot/locale/us/markets/stocks/tickers?tickers=AAPL&apiKey=KxWufj3XvOHL844CeJUPh5DQFp_5F06n`).then(res => {
    console.log(res.data)
    console.log('i fire once');
    setStock1Info(res.data.tickers);
       
    });
  }, []);

  const [stock2Info, setStock2Info] = useState([]);
  useEffect(() => {
    Axios.get(`https://api.polygon.io/v2/snapshot/locale/us/markets/stocks/tickers?tickers=NVDA&apiKey=KxWufj3XvOHL844CeJUPh5DQFp_5F06n`).then(res => {
    console.log(res.data)
    console.log('i fire once');
    setStock2Info(res.data.tickers);
       
    });
  }, []);

  const [stock3Info, setStock3Info] = useState([]);
  useEffect(() => {
    Axios.get(`https://api.polygon.io/v2/snapshot/locale/us/markets/stocks/tickers?tickers=TSLA&apiKey=KxWufj3XvOHL844CeJUPh5DQFp_5F06n`).then(res => {
    console.log(res.data)
    console.log('i fire once');
    setStock3Info(res.data.tickers);
       
    });
  }, []);




const fakeMostActive =[
  {
    "name" : "Tesla",
    "symbol" : "TSLA",
    "price" : 1000.61,
    "change" : 4.76,
    "changePercent" : 0.48,
    "icon": "https://api.polygon.io/v1/reference/company-branding/d3d3LmFwcGxlLmNvbQ/images/2022-05-01_icon.png"
  },
  {
    "name" : "Ethereum",
    "symbol" : "ETH",
    "price" : 3206.10,
    "change" : 121.58,
    "changePercent" : 3.94,
    "icon": "https://api.polygon.io/v1/reference/company-branding/d3d3LmFwcGxlLmNvbQ/images/2022-05-01_icon.png"
  },
  {
    "name" : "Ford Motor Company",
    "symbol" : "F",
    "price" : 21.72,
    "change" : -0.71,
    "changePercent" : 3.16,
    "icon": "https://api.polygon.io/v1/reference/company-branding/d3d3LmFwcGxlLmNvbQ/images/2022-05-01_icon.png"
  },
]
const [stock1Icon, setStock1Icon] = useState('')
useEffect(() => {
  Axios.get(`https://api.polygon.io/v3/reference/tickers/AAPL?apiKey=KxWufj3XvOHL844CeJUPh5DQFp_5F06n`).then(res => {
    console.log(res.data.results.branding.icon_url);
     
    setStock1Icon(res.data.results.branding.icon_url);
  });
}, []);

const [stock2Icon, setStock2Icon] = useState('')
useEffect(() => {
  Axios.get(`https://api.polygon.io/v3/reference/tickers/NVDA?apiKey=KxWufj3XvOHL844CeJUPh5DQFp_5F06n`).then(res => {
    console.log(res.data.results.branding.icon_url);
     
    setStock2Icon(res.data.results.branding.icon_url);
  });
}, []);

const [stock3Icon, setStock3Icon] = useState('')
useEffect(()=> {
  Axios.get(`https://api.polygon.io/v3/reference/tickers/TSLA?apiKey=KxWufj3XvOHL844CeJUPh5DQFp_5F06n`).then(res => {
    console.log(res.data.results.branding.icon_url);
     
    setStock3Icon(res.data.results.branding.icon_url);
  });
}, []);
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}



// const [mostActive, setMostActive] = useState([])

// useEffect(() => {
//   Axios.get(`https://api.polygon.io/v2/snapshot/locale/us/markets/stocks/tickers?tickers=F,TSLA&apiKey=KxWufj3XvOHL844CeJUPh5DQFp_5F06n`).then(res => {
//     console.log(res.data);
     
//     setMostActive(res.data);
     
//   });
// }, []);


const apiKey = '?apiKey=pp66V5fL3BFwWKi7vQ2dYctNV4k9pyR6';
  return (
    <div className='main-most-active'>
      <div className='big-text-most-active'>Most Active</div>
      <div className='top-three-active'>
        {stock1Info?.map((stock, index) => {
  return (
    <div key={index} >
    <div style={{ cursor: 'pointer' }} className='container-most-active'
      onClick={() => {  setSelectedStock(stock.ticker); ToggleStockPage(); window.scroll(0,0);}}
      
      
    >
      
      <img src={`${stock1Icon}` + `${apiKey}`}  className='color-circle-most-active' />
<div className='content-most-active'>
<div className='text-most-active'>
<div className='name-most-active'>Apple Inc.</div>
<div className='share-price-most-active'>{stock.day.c > 0 ? stock.day.c : stock.prevDay.c}</div>
</div>
<div className='text-most-active'>
<div className='ticker-most-active'>{stock.ticker}</div>
<div style={ stock.todaysChange > 0 ? { color:'green'} : {color : 'red'} } className='percentage-most-active'>{stock.todaysChange?.toFixed(2)} ({stock.todaysChangePerc?.toFixed(2)})%</div>
</div>

</div>
</div> 

</div>
  )
  })}
             {stock2Info?.map((stock, index) => {
  return (
    <div key={index} >
    <div style={{ cursor: 'pointer' }} className='container-most-active'
      onClick={() => {  setSelectedStock(stock.ticker); ToggleStockPage();}}
      
      
    >
      
      <img src={`${stock2Icon}` + `${apiKey}`}  className='color-circle-most-active' />
      <div className='content-most-active'>
<div className='text-most-active'>
<div className='name-most-active'>NVIDIA Corporation</div>
<div className='share-price-most-active'>{stock.day.c > 0 ? stock.day.c : stock.prevDay.c}</div>
</div>
<div className='text-most-active'>
<div className='ticker-most-active'>{stock.ticker}</div>
<div style={ stock.todaysChange > 0 ? { color:'green'} : {color : 'red'} } className='percentage-most-active'>{stock.todaysChange?.toFixed(2)} ({stock.todaysChangePerc?.toFixed(2)})%</div>
</div>

</div>
</div> 

</div>
  )
  })}
             {stock3Info?.map((stock, index) => {
  return (
    <div key={index} >
    <div style={{ cursor: 'pointer' }} className='container-most-active'
      onClick={() => {  setSelectedStock(stock.ticker); ToggleStockPage();}}
      
      
    >
      
<img src={`${stock3Icon}` + `${apiKey}`}  className='color-circle-most-active' />
<div className='content-most-active'>
<div className='text-most-active'>
<div className='name-most-active'>Tesla, Inc.</div>
<div className='share-price-most-active'>{stock.day.c > 0 ? stock.day.c : stock.prevDay.c}</div>
</div>
<div className='text-most-active'>
<div className='ticker-most-active'>{stock.ticker}</div>
<div style={ stock.todaysChange > 0 ? { color:'green'} : {color : 'red'} } className='percentage-most-active'>{stock.todaysChange?.toFixed(2)} ({stock.todaysChangePerc?.toFixed(2)})%</div>
</div>

</div>
</div> 

</div>
  )
  })}
{isOpen == true && (
  <div className={`filter-menu ${isOpen == true ? 'active' : ''}`}>

<MyStockPage setSelectedStock={setSelectedStock}   selectedStock={selectedStock} ToggleStockPage={ToggleStockPage} />
</div> 
 )}
      </div>
    </div>
  )
}

export default MostActive