import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import './HomepageNews.css'
import { Link } from 'react-router-dom';

function HomepageNews() {

  const fakeHomepageNews = [
    {
      "headline" : "Gold futures sink 1.5%",
      "content" : "Gold futures head sharply lower Friday morning as comments from President Vladimir Putin appear to dull haven demand for precious metals.",
      "photo" : require('../../Media/NewsPhotos/Screen Shot 2022-05-04 at 10.17.57 AM.png'),
    },
    {
      "headline" : "Short-sellers are scarce and that’s some of the best news the stock market’s had lately",
      "content" : "Gold futures head sharply lower Friday morning as comments from President Vladimir Putin appear to dull haven demand for precious metals.",
      "photo" : require('../../Media/NewsPhotos/Screen Shot 2022-05-04 at 10.18.09 AM.png'),
    },
    {
      "headline" : "Hollywood insiders enter NFT game with new production company",
      "content" : "Electromagnetic Productions Founders Roger Birnbaum and Mark Kimsey join Yahoo Finance Live to discuss engaging with audiences and …",
      "photo" : require('../../Media/NewsPhotos/Screen Shot 2022-05-04 at 10.18.18 AM.png'),
    },
   
  ]

  const [stockNews, setStockNews] = useState([]);
useEffect(() => {
  Axios.get(`https://api.polygon.io/v2/reference/news?limit=3&apiKey=KxWufj3XvOHL844CeJUPh5DQFp_5F06n`).then(res => {
    console.log(res.data.results);
    setStockNews(res.data.results);
     
  });
}, []);


  return (
    <div className='main-homepageNews'>
      <div className='large-text-homepageNews'>For You</div>
      {stockNews?.map((article, index) => (
<a style={{ textDecoration : 'none', color: 'black' }} target="_blank" href={article.article_url}>

<div key={index} className='content-container-homepageNews' style={{cursor: 'pointer'}} >
<img src={article.image_url} className='photo-homepageNews' />
<div className='headline-homepageNews'>{article.title}</div>
<div className='content-homepageNews'>{(article.description?.length) > 150 ?
    `${article.description.substring(0, 150)}...` : article.description
  }</div>
  {/* <div className='content-homepageNews'>{article.description}</div> */}

</div>
</a>
      ))}
     

    </div>
  )
}

export default HomepageNews