import React, { useState } from 'react'
import './Blume5.css'
import cloudLogo from '../Media/Cloud.svg'
import { BrowserRouter as Router, Link, Link as RouterLink } from 'react-router-dom'
import backgroundPhoto from '../Media/imgpsh_anim.jpeg'
import blumeText from '../Media/Blume_text_logo.svg'
import blumeLogo from '../Media/BlumeLockup.svg'

export default function Blume4() {
  const [page, setPage] = useState('page1')
  return (
    <>
    {page == 'page1' && 
   
      <div  className='background-5' onClick={() => { setPage('page2'); }} >
        {/* <img src={backgroundPhoto} className='background-image-5'/> */}
      {/* <div className='blume-text-container'>
        <img style={{ height: '100%', width: 'auto', overflow: 'visable', }} className='blume-text' src={blumeText} alt="cloud-logo"/>
      
      </div> */}
      <div className='blume-text-container'>
        <img style={{ height: 'auto', width: 'auto',  overflow: 'visable', }}  src={blumeText} alt="cloud-logo"/>
      
      </div>

      <div className='nav-container-5'>

        <div className='nav-dot-left-5'>

        </div>
        <div className='nav-dot-right-5' onClick={() => { setPage('page2'); window.scroll(0,0); }}>

        </div>
      </div>
      </div>
      
      }

    {page == 'page2' && 
    
    <div className='main-6'>
     {/* <div className='logo-box'>
        <img style={{  overflow: 'visable', }} className='cloud-logo' src={cloudLogo} alt="cloud-logo"/>
      
      </div> */}
        <div className='logo-box'>
        {/* <img style={{ height: '100%', overflow: 'visable', }} className='cloud-logo' src={cloudLogo} alt="cloud-logo"/> */}
        <img style={{  overflow: 'visable', width: '50vw', marginBottom: '-30vh'}} className='cloud-logo' src={cloudLogo} alt="cloud-logo"/>
      </div>
    <div className='content-6'>
      
    {/* <img style={{ overflow: 'visable', marginBottom: '20px', height: '50vh' }} className='cloud-logo' src={cloudLogo} alt="cloud-logo"/> */}
      {/* <img src={cloudLogo}/>  */}
    
    
      <div className='large-text-6'>Streamline Your Portfolio for Success</div>
      <div className='small-text-6'>Maintain autonomy and manage your investments like an expert. With BLUME, you can get live access to the portfolios of financial professionals and updates when they change. Sign up to get access to your first free verified investor.</div>
      
      <div className='button-container-6'>
        <Link className='text-link' to='/7' component={RouterLink}>
          <button onClick={() => {window.scroll(0,0);}} className='button2'>Grow with Blume</button>
        </Link>
        
      </div>
         <div className='nav-container-6'>

        <div className='nav-dot-right-6' onClick={() => { setPage('page1'); }}></div>
        <div className='nav-dot-left-6'></div>

      </div>
    </div>
  </div>
 
    }

    </>
  )
}
