import React, {useState} from 'react'
import './Blume11.css';

function HowDropdown({how, setHow}) {

const [isActive, setIsActive] = useState(false);
const hows = ['friend', 'ad', 'social media']


  return (
    <div className='dropdown'>
      {isActive ? 
       <div className='dropdown-button-active' onClick={(e) =>
        setIsActive(!isActive)}>
        How Did You Hear About Us <div className='dropdown-arrow-active'></div>
        </div>
      :
      <div className='dropdown-button-selected' onClick={(e) =>
        setIsActive(!isActive)}>
        {how}<div className='dropdown-arrow'></div>
        </div>
       }
      
      {isActive && (
        <div className="dropdown-content">
          {hows.map((how, index) => (
            <div key={index}
            onClick={e => {
              setHow(how)
              setIsActive(false)
            }} 
            className="dropdown-item">{how}</div>
          ))}
        
        
      </div>
      )}
      
      
    </div>
  )
}

export default HowDropdown
