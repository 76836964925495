import React, {useState} from 'react'
import IndustryDropdown from './IndustryDropdown'
import RiskDropdown from './RiskDropdown'
import { fakeInvestors } from '../../FakeData/FakeInvestorData'
import './LeaderboardSearch.css'
import MyInvestorsProfile from '../../MyInvestors/MyInvestorsProfile.js';

function LeaderboardSearch({investorProfile, setInvestorProfile}) {

  const [risk, setRisk] = useState('Risk');
  const [industry, setIndustry] = useState('Industry')


  const [selectedInvestor, setSelectedInvestor] = useState('')

  const currentInvestor = fakeInvestors.filter(i => i.name == selectedInvestor);
  const handleSearch = (event) =>{
  }

  


  const [filteredData, setFilteredData] = useState(fakeInvestors);
  // let result = [];
  // result = fakeInvestors.filter((data) => {
  //   return data.risk.search(risk) != -1;
  // });
  let topThree = fakeInvestors.filter(i => i.risk == `${risk}` && i.industry == `${industry}` );

  if (risk == 'Risk' && industry == 'Industry') {
    topThree = fakeInvestors.filter((investor, index) => index < 3)
  } else {
    topThree = fakeInvestors.filter(i => i.risk.includes(`${risk}`) );
  }
 
const [isOpen, setIsopen] = useState(false);

const ToggleInvestorPage = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
    investorProfile === false ? setInvestorProfile(true) : setInvestorProfile(false);
    // isOpen === true ? setIsopen(false) : setIsopen(true);
}
  return (
    <div className='main-leaderboard' >
      <div className='text-leaderboard'>Leaderboard</div>
      <div className='select-filter-leaderboard'>
        <div>
        <RiskDropdown risk={risk} setRisk={setRisk}/>
        </div>

        <div>
        <IndustryDropdown industry={industry} setIndustry={setIndustry}/>
        </div>
        
      </div>

      <div className='top-three-leaderboard'>
      {topThree.map((investor, index) => (
  <div key={index} className='investor-profile-investors'
    onClick={() => {setSelectedInvestor(investor.name); ToggleInvestorPage(); window.scroll(0,0);}}
  >

    <img src={investor.photo} className='profile-photo-investors'/>
  
  <div className='profile-text-leaderboard'>
    <div className='profile-name-leaderboard'>{investor.name}</div>
    <div className='profile-stats-leaderboard'>ROI: 38% Risk {investor.risk}</div>
    <div className='profile-bio-leaderboard'>{investor.bio?.length > 25 ?
    `${investor.bio.substring(0, 100)}...` : investor.bio
  }</div>
    <div className='profile-tags-leaderboard'>#crypto #tech #growth #biotech</div>
  </div>
</div>
))}

{isOpen == true && (
<div className={`filter-menu ${isOpen == true ? 'active' : ''}`}>



  <MyInvestorsProfile  setSelectedInvestor={setSelectedInvestor} selectedInvestor={selectedInvestor} currentInvestor={currentInvestor} ToggleInvestorPage={ToggleInvestorPage}/>
    </div>
)}

      </div>


    </div>
  )
}

export default LeaderboardSearch