import React, { useState, useEffect, useRef } from 'react'
import * as d3 from 'd3';

import './IndustryDonutChart.css'
function IndustryDonutChart() {

  const [data] = useState([
    { property: '1', value: 50 },
    { property: '2', value: 8 },
    { property: '3', value: 20 },
    { property: '4', value: 10 },
    { property: '4', value: 12 },
   
  ]);

  const svgRef = useRef();
  useEffect(() => {
      const w = 304;
      const h = 304;
      const radius = w / 2;
      const svg = d3.select(svgRef.current)
        .attr('width', w)
        .attr('height', h)
        .style('overflow', 'visable');
       

      const formattedData = d3.pie().value(d => d.value)(data);
      const arcGenerator = d3.arc().innerRadius(130).outerRadius(radius);
      const color = d3.scaleOrdinal().range(["#714595", "#3FBFE6","#D5D609", "#0055A4", "#EF561D" ]);

      svg.selectAll()
        .data(formattedData)
        .join('path')
          .attr('d', arcGenerator)
          .attr('fill', d => color(d.value))
          .style('opacity', 1);
      // svg.selectAll()
      //   .data(formattedData)
      //   .join('text')
      //     .text(d => d.data.property)
      //     .attr('transform', d => `translate(${arcGenerator.centroid(d)})`)
      //     .style('text-anchor', 'middle');
}, [data]);


  return (
    <>
    <div className='industry-donut-chart-container'>
      
   <svg viewBox="-152 -50 304 100"   ref={svgRef}>
     <g>
     <circle cx="0" cy="0" r="120" 
            stroke="#cccccc" 
            stroke-width="1" 
            fill="none"
            />
    <text x="0%" y="-35%" text-anchor="middle" font-size="18" font-family="graphik-medium">
    RISK
      </text>
      <text x="0%" y="45%" text-anchor="middle" font-size="100" font-family="graphik-semibold">
        B
      </text>
    </g>
   </svg>
    </div>
   </>
  )
}

export default IndustryDonutChart