import React, { useState, useEffect } from 'react'
import Blume5 from '../Blume5/Blume5';
import Blume2 from '../Blume2/Blume2';

export default function LandingPage() {
//   const width = window.innerWidth;
  
//   const breakpoint = 620;
  

// const [isMobile, setIsMobile] = useState(true)
 
// //choose the screen size 
// const handleResize = () => {
//   if (window.innerWidth < 720) {
//       setIsMobile(true)
//   } else {
//       setIsMobile(false)
//   }
// }


const [width, setWidth] = useState(window.innerWidth);

function handleWindowSizeChange() {
    setWidth(window.innerWidth);
}
useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);

const isMobile = width <= 750;

// create an event listener
// useEffect(() => {
//   window.addEventListener("resize", handleResize)
// })
  return (
    <div>
        { isMobile?   <Blume5/> : <Blume2 />}
    </div>
  )
}
