import { text } from 'd3';
import React, { useState, useContext, useEffect } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import './BrowseStocks.css'
import Navbar from '../Navbar/Navbar';
import StockPage from './StockPage';
import Axios from 'axios'
import cloudLogo from '../Media/Cloud.svg'
import fillerLogo from '../Media/stock_default.svg'

function BrowseStocks() {


  const [ selectedStock, setSelectedStock] = useState(null)

  const [input, setInput] = useState('');
  
  const stockTickerList = ['AAPL', 'MSFT', 'BITCOIN', 'AMZN', 'TSLA', 'JNJ', 'NVDA','V', 'BB', 'XOM', 'MA' ]
 
  const [searchStocks, setSearchStocks] = useState(stockTickerList)

  const [isOpen, setIsopen] = useState(false);

  const ToggleStockPage = () => {
      isOpen === true ? setIsopen(false) : setIsopen(true);
  }

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const [stockInfo, setStockInfo] = useState([]);



const [dayData, setDayData] = useState({});




// useEffect(() => {
//   Axios.get(`https://mboum-finance.p.rapidapi.com/qu/quote/?symbol=${stockTickerList}`, {
//     headers: {
//       'X-RapidAPI-Host': 'mboum-finance.p.rapidapi.com',
//       'X-RapidAPI-Key': '4e86c4f998mshe715399d2696ee1p1e5fdajsn3676c26d56a4'

//     }
//   }).then(res => {
//   // console.log(res.data)
//   // console.log('i fire once');
//   // setStockInfo(res.data);
     
//   });
// }, []);
useEffect(() => {
  Axios.get(`https://api.polygon.io/v2/snapshot/locale/us/markets/stocks/tickers?tickers=${searchStocks}&apiKey=KxWufj3XvOHL844CeJUPh5DQFp_5F06n`).then(res => {
  console.log(res.data.tickers)
  // console.log('i fire once');
  setStockInfo(res.data.tickers);
     
  });
}, [searchStocks]);





const handleChange = (e) => {
  e.preventDefault();
  setInput(e.target.value);
 
 
  if (e.target.value.length > 0) {
    return setSearchStocks(() => (e.target.value.toUpperCase()));
  } else {
    return setSearchStocks(stockTickerList);
  }
}



const searchStock = async (prop) => {
  Axios.get(`https://api.polygon.io/v2/snapshot/locale/us/markets/stocks/tickers?tickers=${prop}&apiKey=KxWufj3XvOHL844CeJUPh5DQFp_5F06n`).then(res => {
  console.log(res.data)
  console.log('i fire once');
  setStockInfo(res.data.tickers);
     
  });
}


// src={`${iconURL}` + `${apiKey}`} 
const apiKey = '?apiKey=pp66V5fL3BFwWKi7vQ2dYctNV4k9pyR6';

const MyRow = ({ stock }) => {
  const [iconURL, setIconURL] = useState(null);
  const [stockName, setStockName] = useState(null);
  useEffect(() => {
    
    getIcon(stock.ticker).then(setIconURL);
    getName(stock.ticker).then(setStockName);
  }, [stock]);
  // useEffect(() => {
  //   // console.log(getName(stock.ticker))
  //   // getName(stock.ticker).then(setIconURL);
  // }, [stock]);
  // useEffect(() => {
  //   getIcon(stock.symbol).then(setIconURL);
  // }, [stock]);

  return(
    <div key={stock.ticker} >
    <div style={{ cursor: 'pointer' }} className='stocks-container-browse'
      onClick={() => {  setSelectedStock(stock.ticker); ToggleStockPage(); window.scroll(0,0);}}
      
      
    >
      {(`${iconURL}`.length > 10)
      ?
       <img src={`${iconURL}` + `${apiKey}`}  className='color-circle-most-active' /> 
      :
        <img src={fillerLogo} className='color-circle-browse' />
        
      } 
      
<div className='content-most-active'>
<div className='text-most-active'>
<div className='name-most-active'>{stockName}</div>
<div className='share-price-most-active'>{stock.day.c > 0 ? stock.day.c : stock.prevDay.c}</div>
</div>
<div className='text-most-active'>
<div className='ticker-most-active'>{stock.ticker}</div>
<div style={ stock.todaysChange > 0 ? { color:'green'} : {color : 'red'} } className='percentage-most-active'>{stock.todaysChange?.toFixed(2)} ({stock.todaysChangePerc?.toFixed(2)})%</div>
</div>

</div>
</div> 

</div>
  )

}
{stockInfo?.map(stock=> <MyRow key={stock.ticker} stock={stock} />)}

const getIcon = async (prop) => {
  const res = await Axios.get(`https://api.polygon.io/v3/reference/tickers/${prop}?apiKey=KxWufj3XvOHL844CeJUPh5DQFp_5F06n`)
  return(res.data.results.branding.icon_url)
}
const getName = async (prop) => {
 
  const res = await Axios.get(`https://api.polygon.io/v3/reference/tickers/${prop}?apiKey=KxWufj3XvOHL844CeJUPh5DQFp_5F06n`)
  return(res.data.results.name)
}
   


  return (

    <div  className='main-browsestocks'>
<Navbar />
      <div className='large-text-browsestocks'>Browse Stocks</div>

      
  
<div className='searchbar-browsestocks'>
  <input 
    className='input-searchbar-browsestocks' 
    placeholder='Search'
    onChange={handleChange}
    value={input} />

<div className='icon-searchabr'> <SearchIcon style={{ fontSize: 30, marginRight: '3vw' }}/> </div>
</div>
 

   <div className='myRow-container-browse'>
    {stockInfo.map(stock=> <MyRow key={stock.ticker} stock={stock} />)}
   </div>



        
 
 {isOpen == true && (
  <div className={`filter-menu ${isOpen == true ? 'active' : ''}`}>

<StockPage dayData={dayData} setSelectedStock={setSelectedStock}   selectedStock={selectedStock} ToggleStockPage={ToggleStockPage} />
</div> 
 )}
          
      
    
    
    </div>
  )
}

export default BrowseStocks


