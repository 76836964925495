import React from 'react'
import * as yup from 'yup';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Navbar from '../../Navbar/Navbar';
import visaLogo from '../../Media/visa.svg'
import appleLogo from '../../Media/applepay.svg'
import paypalLogo from '../../Media/paypal.svg'
import BackArrow from '../../Media/arrow_back_rounded.svg'



const paymentSchema = yup.object().shape({
  name: yup.string().required(),
  number: yup.number().positive().integer().required(),
  expires: yup.string().required(),
  cvc: yup.number().positive().integer().required(),

}).required();




function Subscribe2({currentInvestor, ToggleProfile, setSubComponent}) {

  const price = 25
  const tax = 0
  const total = price + tax


  const paymentInfo = (data) => {
    console.log({

    })
  }

  const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm ({
    resolver: yupResolver(paymentSchema),

    defaultValues: {
      name: 'Zubaedah Valcova',
      number: '8364 9375 0930 7302',
      expires: '22/20',
      cvc: '847'
    }
  })

  const onSubmit = (data) => {
    console.log(data);
    console.log(currentInvestor);
    ToggleProfile();
    window.scroll(0,0);
    // setSubComponent('main');
   
  };


  return (
    <>
    {currentInvestor.map((investor, index) => (

<div key={index} className='main-subscribe2'>
{/* <Navbar /> */}
<img  className='back-arrow-myinvestors' style={{ height: '2em', marginRight: 'auto', marginTop: '2em' }} src={BackArrow} onClick={() =>  {setSubComponent('main'); window.scroll(0,0);}} />
<form onSubmit={handleSubmit(onSubmit)}>
<div className='payment-info-subscribe2'>
<div className='large-text-subscribe2'>Payment Information</div>

<div className='investor-profile-background-subscribe2'>
  <div className='investor-container-subscribe2'>
  <img src={investor.photo} className='investor-photo-subscribe2'/>
  <div className='investor-name-subscribe2'>Simon Lawrence</div>
  </div>
</div>


<div className='payment-method-container-subscribe2'>
  <div className='small-payment-text-subscribe2'>Choose your payment method</div>
  <div className='payment-methods-subscribe2'>
    <div className='option-container-subscribe2'>
      <img src={visaLogo} className='option-box-subscribe2' />
      <div className='checkmark-subscribe2'>

        <input
          className='filter-checkbox'
          type="radio"
          name="radio"
          defaultChecked
        />

    </div>
    </div>
    <div className='option-container-subscribe2'>
    <img src={appleLogo} className='option-box-subscribe2' />
      <div className='checkmark-subscribe2'>

        <input
          className='filter-checkbox'
          type="radio"
          name="radio"
        />

      </div>
    </div>
    <div className='option-container-subscribe2'>
    <img src={paypalLogo} className='option-box-subscribe2' />
      <div className='checkmark-subscribe2'>

        <input
          className='filter-checkbox'
          type="radio"
          name="radio"
        />

      </div>
    </div>
  </div>
</div>

<div className='card-info-container-subscribe2'>
  <div className='card-row-subscribe2'>
    <div className='text-card-info-subscribe2'>Card Number</div>
    <input type="text" {...register("number", { required: true })} className='large-subscribe2'/>
  </div>
  <hr className='card-info-divider' />

  <div className='card-row-subscribe2'>
    <div className='text-card-info-subscribe2'>Card Holder</div>
    <input type="text" {...register("name", { required: true })} className='large-subscribe2'/>
  </div>

  <hr className='card-info-divider' />

  <div className='card-row-subscribe2'>
    <div className='text-card-info-subscribe2'>Expires</div>
    <input type="text" {...register("expires", { required: true })} className='small-input-subscribe2'/>

    <div className='cvc-info-subscribe2'>CVC</div>
    <input type="text" {...register("cvc", { required: true })} className='small-input-subscribe2'/>
  </div>

  <hr className='card-info-divider' />

</div>


<div className='save-info-subscribe2'>
  <div> 
    <input
      className='filter-checkbox'
      type="checkbox"
      defaultChecked
    />
  </div>
  <div className='text-save-info-subscribe2'>Save credit information</div>
</div>

<div className='total-subscribe2'>
  <div className='row-total-subscribe2'>
    <div className='subtotal-text-subscribe2'>Subtotal</div>
    <div className='price-subscribe2'>${price}.00</div>
  </div>
  <div className='row-total-subscribe2'>
    <div className='subtotal-text-subscribe2'>Tax</div>
    <div className='price-subscribe2'>${tax}.00</div>
  </div>
  <hr className='card-info-divider' />

  <div className='row-total-subscribe2'>
    <div className='total-text-subscribe2'>Total</div>
    <div className='total-price-subscribe2'>${total}.00</div>
  </div>
</div>
<div className='button-container-subscribe2'>
  <button className='button2'>Complete Order</button>
</div>






</div>
</form>
</div>

    ))}
    </>
    
  )
}

export default Subscribe2