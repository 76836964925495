import React, {useState, useEffect} from 'react'
import Navbar from '../Navbar/Navbar'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PlusIcon from '../Media/blueplus_whitecirlce.svg'
import './MyStocks.css'

import Dialog from '@mui/material/Dialog';
import closeX from '../Media/closex.svg'
import MyStockPage from './MyStockPage';
import { color } from 'd3';
import Axios from 'axios'
import fillerLogo from '../Media/stock_default.svg'

function MyStocks() {



  const myStockTickerList = ['AAPL', 'MSFT', 'F', 'AMZN', 'TSLA', 'NVDA' ]
  const [currentStockList, setCurrentStockList] = useState(myStockTickerList);

  const [selectedStock, setSelectedStock] = useState(null)
  const [stockInfo, setStockInfo] = useState([]);

  const [isOpen, setIsopen] = useState(false);

  const ToggleStockPage = () => {
      isOpen === true ? setIsopen(false) : setIsopen(true);
  }

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


const [open, setOpen] = React.useState(false);
const [fullWidth, setFullWidth] = React.useState(true);
const handleClickOpen = () => {
setOpen(true);
};

const handleClose = () => {
setOpen(false);
};



function capitalizeFirstLetter(string) {
return string.charAt(0).toUpperCase() + string.slice(1);
}









useEffect(() => {
  Axios.get(`https://api.polygon.io/v2/snapshot/locale/us/markets/stocks/tickers?tickers=${currentStockList}&apiKey=KxWufj3XvOHL844CeJUPh5DQFp_5F06n`).then(res => {
  console.log(res.data.tickers)
  // console.log('i fire once');
  setStockInfo(res.data.tickers);
     
  });
}, [currentStockList]);


const handleDeleteClick = (selectedTicker) => {
  const newStocks = [...currentStockList];
console.log(selectedTicker)
  const newList = newStocks.filter((stock) => stock !== selectedTicker);
  
  setCurrentStockList(newList);
  console.log(currentStockList);
}


const apiKey = '?apiKey=pp66V5fL3BFwWKi7vQ2dYctNV4k9pyR6';


const MyRow = ({ stock, index }) => {
  const [iconURL, setIconURL] = useState(null);
  const [stockName, setStockName] = useState(null);
  const [touchStart, setTouchStart] = useState(null)
const [touchEnd, setTouchEnd] = useState(null)

const minSwipeDistance = 50 

const onTouchStart = (e) => {
  setTouchEnd(null) 
  setTouchStart(e.targetTouches[0].clientX)
}

const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

const onTouchEnd = (index) => {
  if (!touchStart || !touchEnd) return
  const distance = touchStart - touchEnd
  const isLeftSwipe = distance > minSwipeDistance
  const isRightSwipe = distance < -minSwipeDistance
  if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right')
//  console.log('swipe')
if (isLeftSwipe) setDeleteStock(deleteStock => deleteStock === index ? null : index)
if (isRightSwipe) setDeleteStock(null)
}

  useEffect(() => {
    getIcon(stock.ticker).then(setIconURL);
    getName(stock.ticker).then(setStockName);
  }, [stock]);

  const ToggleDelete = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  }

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const handleClickOpen = () => {
    setOpen(true);
  };

  // const [isOpen, setIsopen] = useState(false);

  // const ToggleStockPage = () => {
  //   isOpen === true ? setIsopen(false) : setIsopen(true);
  // }

function capitalizeFirstLetter(string) {
return string.charAt(0).toUpperCase() + string.slice(1);
}



  return(
 
         <div key={index} id={index} 
      onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={() => {onTouchEnd(index)}} 
      className='row-container-myinvestors' >
   {/* <button onClick={() => {setDeleteStock(deleteStock => deleteStock === index ? null : index)}}>delete</button>  */}
    {/* <div style={{ cursor: 'pointer' }} 
    // className='container-most-active'
    className={` ${deleteStock === index ? 'container-myinvestors-active' : 'container-myinvestors'}`}
    // onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}
      onClick={() => {  setSelectedStock(stock.ticker); ToggleStockPage(); window.scroll(0,0);}}
    > */}
       <div  id={stock.ticker} style={{ cursor: 'pointer', transition: 0.5 }} className={` ${deleteStock === index ? 'container-myinvestors-active' : 'container-myinvestors'}`}
          onClick={() => {setSelectedStock(stock.ticker); ToggleStockPage(); window.scroll(0,0);}}
          
        >
       
      
      {(`${iconURL}`.length > 10)
      ?
       <img src={`${iconURL}` + `${apiKey}`}  className='color-circle-most-active' /> 
      :
        <img src={fillerLogo} className='color-circle-browse' />
        
      } 
      
<div className='content-most-active'>
<div className='text-most-active'>
<div className='name-most-active'>{stockName}</div>
<div className='share-price-most-active'>{stock.day.c > 0 ? stock.day.c : stock.prevDay.c}</div>
</div>
<div className='text-most-active'>
<div className='ticker-most-active'>{stock.ticker}</div>
<div style={ stock.todaysChange > 0 ? { color:'green'} : {color : 'red'} } className='percentage-most-active'>{stock.todaysChange?.toFixed(2)} ({stock.todaysChangePerc?.toFixed(2)})%</div>
</div>

</div>

</div> 
<div 
         onClick={() => {handleDeleteClick(stock.ticker); setDeleteStock(null); }}
          className={`delete-button-container-mystocks ${deleteStock === index ? 'active' : ''}`}>
          {/* <div  className='exit-button-menu' ></div>           */}
      <DeleteOutlineIcon sx={{ color: 'white', fontSize: '35px'}} /> 
        </div>

{/* <div style={{cursor: 'pointer'}} onClick={() => {handleDeleteClick(stock.ticker);}}>X</div> */}
</div>

  )

}
{stockInfo?.map(stock=> <MyRow key={stock.ticker} stock={stock} />)}

const getIcon = async (prop) => {
  const res = await Axios.get(`https://api.polygon.io/v3/reference/tickers/${prop}?apiKey=KxWufj3XvOHL844CeJUPh5DQFp_5F06n`)
  return(res.data.results.branding.icon_url)
}

const getName = async (prop) => {
 
  const res = await Axios.get(`https://api.polygon.io/v3/reference/tickers/${prop}?apiKey=KxWufj3XvOHL844CeJUPh5DQFp_5F06n`)
  return(res.data.results.name)
}




const [deleteStock, setDeleteStock] = useState(null);

const [selectedInvestor, setSelectedInvestor] = useState('')



// const [touchStart, setTouchStart] = useState(null)
// const [touchEnd, setTouchEnd] = useState(null)

// const minSwipeDistance = 50 

// const onTouchStart = (e) => {
//   setTouchEnd(null) 
//   setTouchStart(e.targetTouches[0].clientX)
// }

// const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

// const onTouchEnd = (index) => {
// if (!touchStart || !touchEnd) return
// const distance = touchStart - touchEnd
// const isLeftSwipe = distance > minSwipeDistance
// const isRightSwipe = distance < -minSwipeDistance
// if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right')
// //  console.log('swipe')
// if (isRightSwipe)  toggleDeleteButton(index)
// // setDeleteStock(deleteStock => deleteStock === index ? null : index)


// // ToggleDelete();
// // setDeleteStock(deleteStock => deleteStock === index ? null : index)
// }

// const toggleDeleteButton = (index) => {
// setDeleteStock(deleteStock => deleteStock == index ? null : index)
// }




  return (
    <>
    
    <div className='main-mystocks'>
    <Navbar />

    <Dialog 
    PaperProps={{
      style: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    }} onClose={handleClose} open={open}>
      
      <div className='remove-dialog-mystocks'>
        <div className='x-dialog-mystocks'> <img style={{ height: 60 }}src={closeX}/></div>
        <div className='large-text-dialog-mystocks'>Are you sure you <br/> want to remove <br/> Dylan Welch?</div>
        <div className='small-text-dialog-mystocks'>Yes please remove</div>
      </div>

    </Dialog>
    <div className='header-row-mystocks'>
      <div className='large-text-mystocks'>My Stocks</div>
      <div> 
        <img src={PlusIcon}/>
      </div>
    </div>
    
    
<div className='content-container-mystocks'>

</div>



<div className='myRow-container-mystocks'>
  {stockInfo.map((stock, index)=> <MyRow key={stock.ticker} stock={stock} index={index}/>)}
</div>


{/* {stockInfo.map((stock, index) => {

return (
  <div>{stock.ticker}</div>
)


})} */}






{isOpen == true && (
  <div className={`filter-menu ${isOpen == true ? 'active' : ''}`}>

<MyStockPage setSelectedStock={setSelectedStock}   selectedStock={selectedStock} ToggleStockPage={ToggleStockPage} />
</div> 
 )}
    </div>
    </>
  )
}

export default MyStocks


// const MyRow = ({ stock, index }) => {
//   const [iconURL, setIconURL] = useState(null);
//   const [stockName, setStockName] = useState(null);
//   const [touchStart, setTouchStart] = useState(null)
// const [touchEnd, setTouchEnd] = useState(null)

// const minSwipeDistance = 50 

// const onTouchStart = (e) => {
//   setTouchEnd(null) 
//   setTouchStart(e.targetTouches[0].clientX)
// }

// const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

// const onTouchEnd = (index) => {
//   if (!touchStart || !touchEnd) return
//   const distance = touchStart - touchEnd
//   const isLeftSwipe = distance > minSwipeDistance
//   const isRightSwipe = distance < -minSwipeDistance
//   if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right')
// //  console.log('swipe')
// if (isRightSwipe) setDeleteStock(deleteStock => deleteStock === index ? null : index)

// }

//   useEffect(() => {
//     getIcon(stock.ticker).then(setIconURL);
//     getName(stock.ticker).then(setStockName);
//   }, [stock]);

//   const ToggleDelete = () => {
//     isOpen === true ? setIsopen(false) : setIsopen(true);
//   }

//   const [open, setOpen] = React.useState(false);
//   const [fullWidth, setFullWidth] = React.useState(true);
//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   // const [isOpen, setIsopen] = useState(false);

//   // const ToggleStockPage = () => {
//   //   isOpen === true ? setIsopen(false) : setIsopen(true);
//   // }

// function capitalizeFirstLetter(string) {
// return string.charAt(0).toUpperCase() + string.slice(1);
// }



//   return(
//     <div 
//     onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={() => {onTouchEnd(index)}}
//     key={stock.ticker} >
   
//    <button onClick={() => {setDeleteStock(deleteStock => deleteStock === index ? null : index)}}>delete</button> 
//     <div style={{ cursor: 'pointer' }} 
//     // className='container-most-active'
//     className={` ${deleteStock === index ? 'container-myinvestors-active' : 'container-myinvestors'}`}
//     // onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}
//       onClick={() => {  setSelectedStock(stock.ticker); ToggleStockPage(); window.scroll(0,0);}}
      
      
//     >
       
      
//       {(`${iconURL}`.length > 10)
//       ?
//        <img src={`${iconURL}` + `${apiKey}`}  className='color-circle-most-active' /> 
//       :
//         <img src={fillerLogo} className='color-circle-browse' />
        
//       } 
      
// <div className='content-most-active'>
// <div className='text-most-active'>
// <div className='name-most-active'>{stockName}</div>
// <div className='share-price-most-active'>{stock.day.c}</div>
// </div>
// <div className='text-most-active'>
// <div className='ticker-most-active'>{stock.ticker}</div>
// <div style={ stock.todaysChange > 0 ? { color:'green'} : {color : 'red'} } className='percentage-most-active'>{stock.todaysChange?.toFixed(2)} ({stock.todaysChangePerc?.toFixed(2)})%</div>
// </div>

// </div>
// <div 
//          onClick={() => {setSelectedStock(stock.ticker); handleClickOpen(); }}
//           className={`delete-button-container-mystocks ${deleteStock === index ? 'active' : ''}`}>
//           {/* <div  className='exit-button-menu' ></div>           */}
//       <DeleteOutlineIcon sx={{ color: 'white', fontSize: '35px'}} /> 
//         </div>
// </div> 

// {/* <div style={{cursor: 'pointer'}} onClick={() => {handleDeleteClick(stock.ticker);}}>X</div> */}
// </div>

//   )

// }