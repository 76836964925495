import React, {useState} from 'react'
import Navbar from '../Navbar/Navbar';
import './FilterIndustry.css'





function FilterIndustry({crypto, setCrypto, energy, setEnergy, materials, setMaterials, industrials, setIndustrials, discretionary, setDiscretionary, staples, setStaples, health, setHealth, financials, setFinancials, technology, setTechnology, telecommunication, setTelecommunication, utilities, setUtilities, realEstate, setRealEstate}) {

    

  const cryptoHandleOnChange = () => {
    setCrypto(!crypto);
  };

  const energyHandleOnChange = () => {
    setEnergy(!energy);
  };

  const materialsHandleOnChange = () => {
    setMaterials(!materials);
  };

  const industrialsHandleOnChange = () => {
    setIndustrials(!industrials);
  };

  const discretionaryHandleOnChange = () => {
    setDiscretionary(!discretionary);
  };

  const staplesHandleOnChange = () => {
    setStaples(!staples);
  };

  const healthHandleOnChange = () => {
    setHealth(!health);
  };

  const financialsHandleOnChange = () => {
    setFinancials(!financials);
  };

  const technologyHandleOnChange = () => {
    setTechnology(!technology);
  };

  const telecommunicationHandleOnChange = () => {
    setTelecommunication(!telecommunication);
  };

  const utilitiesHandleOnChange = () => {
    setUtilities(!utilities);
  };

  const realEstateHandleOnChange = () => {
    setRealEstate(!realEstate);
  };
  
  const [isOpen, setIsopen] = useState(false);

  const ToggleSidebar = () => {
      isOpen === true ? setIsopen(false) : setIsopen(true);
  }
  
    return (
      <div className='filter-main'>
        <Navbar />
        <div className='large-text-row-filter'>
        <div className='large-text-filter'>Filter Categories</div>
        <div  className='exit-button-menu-filter' onClick={ToggleSidebar}>+</div>
        </div>
        
        <div className='filter-list'>
          
          <div className='category-industry'>
            <div className='category-name-industry'>Crypto </div>
          
            <input
            className='filter-checkbox'
            type="checkbox"
            checked={crypto}
            onChange={cryptoHandleOnChange}
           
            />
           
        </div>
     
          <hr className='category-divider' />
  
          <div className='category-industry'>
            <div className='category-name-industry'>Energy </div>
          
            <input
            className='filter-checkbox'
            type="checkbox"
            checked={energy}
            onChange={energyHandleOnChange}
            />
            
        </div>
        
          <hr className='category-divider' />
  
          <div className='category-industry'>
            <div className='category-name-industry'>Materials </div>
          
            <input
            className='filter-checkbox'
            type="checkbox"
            checked={materials}
            onChange={materialsHandleOnChange}
            />
            
        </div>
  
          <hr className='category-divider' />

          <div className='category-industry'>
            <div className='category-name-industry'>Industrials</div>
          
            <input
            className='filter-checkbox'
            type="checkbox"
            checked={industrials}
            onChange={industrialsHandleOnChange}
            />
            
          </div>
  
          <hr className='category-divider' />

          <div className='category-industry'>
            <div className='category-name-industry'>Consumer Discretionary</div>
          
            <input
            className='filter-checkbox'
            type="checkbox"
            checked={discretionary}
            onChange={discretionaryHandleOnChange}
            />
            
          </div>
  
          <hr className='category-divider' />

          <div className='category-industry'>
            <div className='category-name-industry'>Consumer Staples</div>
          
            <input
            className='filter-checkbox'
            type="checkbox"
            checked={staples}
            onChange={staplesHandleOnChange}
            />
            
          </div>
  
          <hr className='category-divider' />

          <div className='category-industry'>
            <div className='category-name-industry'>Health Care</div>
          
            <input
            className='filter-checkbox'
            type="checkbox"
            checked={health}
            onChange={healthHandleOnChange}
            />
            
          </div>
  
          <hr className='category-divider' />

          <div className='category-industry'>
            <div className='category-name-industry'>Financials</div>
          
            <input
            className='filter-checkbox'
            type="checkbox"
            checked={financials}
            onChange={financialsHandleOnChange}
            />
            
          </div>
  
          <hr className='category-divider' />

          <div className='category-industry'>
            <div className='category-name-industry'>Information Technology</div>
          
            <input
            className='filter-checkbox'
            type="checkbox"
            checked={technology}
            onChange={technologyHandleOnChange}
            />
            
          </div>
  
          <hr className='category-divider' />

          <div className='category-industry'>
            <div className='category-name-industry'>Telecommunication Services</div>
          
            <input
            className='filter-checkbox'
            type="checkbox"
            checked={telecommunication}
            onChange={telecommunicationHandleOnChange}
            />
            
          </div>
  
          <hr className='category-divider' />

          <div className='category-industry'>
            <div className='category-name-industry'>Utilities</div>
          
            <input
            className='filter-checkbox'
            type="checkbox"
            checked={utilities}
            onChange={utilitiesHandleOnChange}
            />
            
          </div>
  
          <hr className='category-divider' />

          <div className='category-industry'>
            <div className='category-name-industry'>Real Estate</div>
          
            <input
            className='filter-checkbox'
            type="checkbox"
            checked={realEstate}
            onChange={realEstateHandleOnChange}
            />
            
          </div>
  
          <hr className='category-divider' />
          
        </div>
        <div className='hashtag-filter'>
          <div className='large-text-filter'>Hashtags</div>
        </div>
        <div className='hashtag-container-filter'>
          <div className='hashtag-list-filter'>#crypto</div>
          <div className='hashtag-list-filter'>#tech</div>
          <div className='hashtag-list-filter'>#biotech</div>
          <div className='hashtag-list-filter'>#utilities</div>
          <div className='hashtag-list-filter'>#realestate</div>
          <div className='hashtag-list-filter'>#bluechip</div>
        </div>
        <div className='bottom-buttons-filter'>
          <button style={{ marginTop: 66,  }} className='button2'>Apply Filters</button>
          <div className='small-text-filter'>Reset Filters</div>
        </div>
        
        
      </div>
    )
  }

export default FilterIndustry