import React from 'react'
import notificationIcon from '../Media/full_bell.svg'
import './Navbar.css'
import MenuButton from '../Menu/MenuButton'
import { BrowserRouter as Router, Link, Link as RouterLink } from 'react-router-dom'

function Navbar() {
  return (
    <>
   

    
      <div className='navbar-container'>
        <div className='menu-icon'>
          
          <MenuButton />
        </div>

        <div className='notification-icon'>
        <Link className='text-link' to='/notifications' component={RouterLink}>
          <img style={{ height: 60 }}src={notificationIcon}/>
          </Link>
        </div>
      </div>
     
    </>
  )
}

export default Navbar
